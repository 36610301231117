import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe to transform an array of objects into an array of a single one of the object properties.
 */
@Pipe({
  name: 'pluck',
})
export class PluckPipe implements PipeTransform {
  transform(input: unknown, key: string): any {
    if (!Array.isArray(input) || !key) {
      return input;
    }

    return input.map((value: any) => {
      return value[key];
    });
  }
}
