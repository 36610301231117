import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserService } from '../../../user/user.service';
import { ContactService } from '../../services/contact.service';

@Component({
  selector: 'app-add-lead-dialog',
  templateUrl: './add-lead-dialog.component.html',
  styleUrls: ['./add-lead-dialog.component.scss'],
})
export class AddLeadDialogComponent {
  /**
   * The form group to hold the lead data to be saved.
   */
  formGroup = this.fb.group({
    idtLead: new FormControl<number | null>(null, [Validators.required]),
  });

  /**
   * The user options to be leads.
   */
  users$ = this.userService.getEmployees();

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<AddLeadDialogComponent>,
    private contactService: ContactService,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) private data: { idtContact: number }
  ) {}

  /**
   * Save the lead.
   */
  save(): void {
    if (this.formGroup.valid) {
      const idtLead = this.formGroup.controls.idtLead.value as number;

      this.contactService
        .updateLeads(this.data.idtContact, {
          idtLead,
        })
        .subscribe(() => {
          this.dialogRef.close();
        });
    }
  }
}
