import { signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

/**
 * The route data expected by the component.
 */
interface RouteData {
  outlet?: 'sidenav' | 'primary';
}

/**
 * Base class for components that listen to route data that holds outlet information.
 */
export abstract class OutletAwareComponent {
  /**
   * The route outlet name.
   */
  outlet = signal<'primary' | 'sidenav'>('sidenav');

  constructor(protected route: ActivatedRoute) {
    this.route.data.subscribe((data: RouteData) => {
      if (data.outlet) {
        this.outlet.set(data.outlet);
      }
    });
  }
}
