import { AbstractControl, FormControl, FormGroupDirective, NgForm, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { DateTime } from 'luxon';

/**
 * Makes the field required if the predicate function returns true
 */
export function requiredIfValidator(predicate: () => boolean): ValidatorFn {
  return (formControl: AbstractControl) => {
    if (!formControl.parent) {
      return null;
    }

    if (predicate()) {
      return Validators.required(formControl);
    }
    return null;
  };
}

/**
 * Validator that requires a form group to have a least one defined property.
 *
 * @param form the form gorup reference
 * @returns ValidationErrors object witht the "atLeastOneRequired" property if invalid, or null if valid
 */
export function atLeastOneValue(form: AbstractControl): ValidationErrors | null {
  return Object.keys(form.value).some((key) => !!form.value[key]) ? null : { atLeastOneRequired: 'At least one should be selected' };
}

/**
 * Validator factory to create a validation function for start and end date.
 *
 * @param from the initial date field name
 * @param to the end data field name
 * @returns a function that will validate the dates using the provided field names
 */
export function dateLessThan(from: string, to: string): ValidatorFn {
  return (group: AbstractControl) => {
    const f = group.get(from)!;
    const t = group.get(to)!;

    // Convert string values to DateTime (assuming format is always an ISO date)
    const fValue = f.value && typeof f.value === 'string' ? DateTime.fromISO(f.value) : f.value;
    const tValue = t.value && typeof t.value === 'string' ? DateTime.fromISO(t.value) : t.value;

    if (fValue && tValue && fValue > tValue) {
      return {
        dateLessThan: 'Invalid period',
      };
    }
    return null;
  };
}

/**
 * Error state matcher, to allow marking a field as invalid based on the form having a dateLessThan error.
 * DateLessThanValidator errors are triggered when a validation between two fields fail, therefore it is a form validation, as field validators can only read that field value for the validation.
 */
export class DateLessThanMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return (control?.touched && (form?.hasError('dateLessThan') || control?.invalid)) || false;
  }
}

/**
 * Validator factory to create validation function for percent fields sum.
 *
 * @param fields the fiels to sum
 * @returns a function that will validates the provided fiels sum is not larger than 100
 */
export function sumPercent(fields: string[]): ValidatorFn {
  return (group: AbstractControl) => {
    const total = fields.reduce((sum, field) => {
      return sum + (group.get(field)?.value || 0);
    }, 0);

    if (total > 100) {
      return {
        sumPercent: 'Sum of percentages greater than 100%',
      };
    }

    return null;
  };
}

/**
 * Error state matcher to allow marking a fields as invalid when the form has a "sumPercent" error.
 */
export class SumPercentMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && (form?.hasError('sumPercent') || control?.invalid) && (control.dirty || control.touched || form?.submitted));
  }
}
