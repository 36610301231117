import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BasicContact, ContactTypeEnum } from 'app/modules/common/business/contact/model/contact.model';
import { Document, DocumentType } from '../model/document.model';

/**
 * The form group definition used in the component.
 */
export type DocumentFormGroup = FormGroup<{
  type: FormControl<number | null>;
  contacts: FormControl<BasicContact[]>;
  comments: FormControl<string>;
  file?: FormControl<File>;
  document?: FormControl<Document>;
}>;

/**
 * Component for the form for documents upload and update.
 */
@Component({
  selector: 'app-document-form',
  templateUrl: './document-form.component.html',
  styleUrls: ['./document-form.component.scss'],
})
export class DocumentFormComponent {
  /**
   * The formGroup to store the input values.
   */
  @Input()
  formGroup!: DocumentFormGroup;

  /**
   * Setter for document type options.
   */
  @Input()
  set documentTypes(types: DocumentType[] | null) {
    this.allDocumentTypes = types || [];
  }

  /**
   * All document types options.
   */
  allDocumentTypes: DocumentType[] = [];

  /**
   * Whether to display the remove button.
   */
  @Input()
  removable = false;

  /**
   * Emits when the remove button is clicked.
   */
  @Output()
  removed = new EventEmitter<void>();

  /**
   * Contact types enum.
   */
  contactTypes = ContactTypeEnum;

  /**
   * Builds the formGroup required for this component.
   *
   * @param file the file that will be uploaded, for new documents only
   * @param document the document entity loaded from the server, for editions only
   * @returns the created formGroup
   */
  static buildFormGroup(file?: File, document?: Document) {
    const formGroup: DocumentFormGroup = new FormGroup({
      type: new FormControl<number | null>(document?.idtDocumentType || null, Validators.required),
      contacts: new FormControl<BasicContact[]>(document?.contacts || [], {
        validators: Validators.required,
        nonNullable: true,
      }),
      comments: new FormControl<string>(document?.comments || '', { nonNullable: true }),
    });

    // For new documents, the file must be passed
    if (file) {
      formGroup.addControl('file', new FormControl<File>(file, { nonNullable: true }));
    }

    // For existing documents, the document entity must be passed
    if (document) {
      formGroup.addControl('document', new FormControl<Document>(document, { nonNullable: true }));
    }

    return formGroup;
  }

  /**
   * Get the extension from the selected file.
   *
   * @param fileName the name of the file
   * @returns the file extension
   */
  getFileExtension(): string {
    const fileName = this.getFileName();

    if (fileName) {
      return fileName.substring(fileName.lastIndexOf('.') + 1);
    }

    return '';
  }

  /**
   * Get the name of the file.
   *
   * @returns the name of the file
   */
  getFileName(): string {
    const fileControl = this.formGroup.controls.file || this.formGroup.controls.document;

    return fileControl!.value.name;
  }

  /**
   * Handles when the remove button is clicked, emitting the an event for the parent component.
   */
  remove(): void {
    this.removed.emit();
  }
}
