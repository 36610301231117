<div class="abs-padding" fxAlign="center center">
  @if (status()) {
    @switch (status()) {
      @case ('saving') {
        <div fxFlex fxLayout="row">
          <mat-progress-spinner diameter="20" mode="indeterminate" />
          <div fxAlign="center center" class="abs-margin-half-left abs-margin-auto-vertical">Saving draft...</div>
        </div>
      }
      @case ('saved') {
        <div fxFlex fxLayout="row">
          <mat-icon class="abs-color-positive" fontIcon="mdi-check-circle" />
          <div class="abs-margin-half-left abs-margin-auto-vertical">Draft saved.</div>
        </div>
      }
      @case ('error') {
        <div fxFlex fxLayout="row">
          <mat-icon class="abs-color-error" fontIcon="mdi-alert-circle" />
          <div class="abs-margin-half-left abs-margin-auto-vertical abs-color-error">Couldn't save draft, please keep a copy of this content.</div>
        </div>
      }
    }
  }
</div>
