import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ContactList, ContactViewTO } from 'app/modules/common/business/contact/model/contact.model';
import { ContactService } from 'app/modules/common/business/contact/services/contact.service';
import { map, pairwise, startWith, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ContactAutocompleteService {
  constructor(private contactService: ContactService) {}

  /**
   * Setups the behavior to load companies/keyContacts when filling either the person or company fields.
   */
  setupContactsBehavior(personsForm: AbstractControl, companiesForm: AbstractControl): void {
    personsForm.valueChanges
      .pipe(
        startWith([]),
        pairwise(),
        map(([prev, cur]: [ContactViewTO[], ContactViewTO[]]) => cur.filter((x) => !prev.some((x2) => x2.idtContact === x.idtContact))),
        tap((diff) => {
          diff.forEach((person) => {
            if (person.idtCompany) {
              this.contactService.getCompanyContact(person.idtCompany).subscribe((company) => {
                if (company) {
                  if (!companiesForm?.value.some((c: ContactList) => c.idtContact === company.idtContact)) {
                    companiesForm?.setValue([...companiesForm.value, company]);
                  }
                }
              });
            }
          });
        }),
      )
      .subscribe();
  }
}
