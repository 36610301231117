<h1 mat-dialog-title>Duplicated document</h1>
<div mat-dialog-content>
  <div>The files below already exist in the system</div>

  <div class="tw-my-1 tw-p-1">
    <div class="tw-flex tw-gap-1">
      <div class="tw-w-1/3">File</div>
      <div class="tw-w-1/3">Duplicate of</div>
      <div class="tw-w-1/3">Linked to</div>
    </div>
    <mat-divider />
    @for (doc of data; track $index) {
      <div class="tw-flex tw-items-center tw-gap-1">
        <div class="abs-text-overflow-ellipsis tw-w-1/3">{{ doc.original }}</div>
        <div class="abs-text-overflow-ellipsis tw-w-1/3">{{ doc.duplicated.name }}</div>
        <div class="abs-text-overflow-ellipsis tw-flex tw-w-1/3 tw-items-center">
          <span class="abs-text-overflow-ellipsis">{{ doc.duplicated.contacts[0].name }}</span>
          @if (doc.duplicated.contacts.length > 1) {
            <mat-chip [matTooltip]="doc.duplicated.contacts | pluck: 'name' | join: '\n'" matTooltipClass="multline-tooltip" class="tw-ml-1">
              + {{ doc.duplicated.contacts.length - 1 }}
            </mat-chip>
          }
        </div>
      </div>
    }
  </div>

  <div>Do you want to upload them anyway?</div>
</div>
<div mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button mat-button [mat-dialog-close]="true">Confirm</button>
</div>
