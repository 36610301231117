import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogService } from 'app/modules/common/framework/dialog/dialog.service';
import { FileService } from 'app/modules/common/framework/services/file.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TrackerService } from '../../tracker/services/tracker.service';
import { DocumentAddComponent } from '../document-add/document-add.component';
import { Document, DocumentTO, DocumentType, DocumentUploadData, DocumentUploadDataWithFile } from '../model/document.model';

/**
 * Service class for document
 */
@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  constructor(
    private http: HttpClient,
    private fileService: FileService,
    private dialog: MatDialog,
    private trackerService: TrackerService,
    private dialogService: DialogService,
  ) {}

  /**
   * Performs the download based on hash and filename
   * @param id the id of the document entity
   */
  download(id: number): void {
    this.getUrlToDownload(id)
      .pipe(tap((url) => this.fileService.download(url)))
      .subscribe();
  }

  /**
   * Gets the presigned url of file
   * @param id the id of the document entity
   */
  private getUrlToDownload(id: number): Observable<string> {
    return this.http.get<string>(`${environment.apiUrl}/doc/url/${id}`);
  }

  /**
   * Get the document types from the server.
   *
   * @returns the list of all document types wrapped in an observable
   */
  getDocumentTypes(): Observable<DocumentType[]> {
    return this.http.get<DocumentType[]>(`${environment.apiUrl}/doc/type`);
  }

  /**
   * Uploads a new document.
   *
   * @param values the values to create the new document
   * @returns an observable that resolves to void when the server responds
   */
  uploadDocument(values: DocumentUploadDataWithFile): Observable<DocumentTO> {
    const data = new FormData();
    data.append('document', values.file);
    data.append('idtDocumentType', values.type.toString());

    if (values.comments) {
      data.append('comments', values.comments);
    }

    values.contacts.forEach((c, index) => {
      data.append(`contacts[${index}]`, c.idtContact.toString());
    });

    if (values.idtLog) {
      data.append('idtLog', values.idtLog.toString());
    }

    if (values.ignoreDuplicates) {
      data.append('ignoreDuplicates', 'true');
    }

    return this.http.post<DocumentTO>(`${environment.apiUrl}/doc`, data);
  }

  /**
   * Get a document entity from the server by the id.
   *
   * @param id the id of the document to be updated
   * @returns the document entity found wrapped in an observable
   */
  getOne(id: number): Observable<Document> {
    return this.http.get<Document>(`${environment.apiUrl}/doc/${id}`);
  }

  updateOne(idtDocument: number, document: DocumentUploadData): Observable<void> {
    const body = {
      comments: document.comments,
      idtDocumentType: document.type,
      contacts: document.contacts.map((c) => c.idtContact),
    };

    return this.http.put<void>(`${environment.apiUrl}/doc/${idtDocument}`, body);
  }

  delete(idtDocument: number): Observable<void> {
    return this.http.delete<void>(`${environment.apiUrl}/doc/${idtDocument}`);
  }

  /**
   * Opens dialog for uploading documents.
   *
   * @param idtLog option log id to link the document to
   * @returns the dialog reference
   */
  openUploadDocumentDialog(idtLog?: number): MatDialogRef<DocumentAddComponent> {
    const dialogRef = this.dialog.open(DocumentAddComponent, {
      width: '700px',
      height: '70vh',
      panelClass: 'fullscreen-dialog-mobile',
      closeOnNavigation: true,
      data: {
        idtLog,
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.trackerService.event('doc_new', 'closed');
    });

    return dialogRef;
  }
}
