<app-custom-list-item class="draft">
  @if (draft(); as draft) {
    <div fxFlex fxLayout="column" class="hide-overflow">
      <div fxFlex fxLayoutAlign="center center">
        <span class="abs-margin-half-right abs-bold">
          {{ draft.data?.reportedDate | date: 'MM/dd/yyyy' }}
        </span>
        @if (draft?.data?.contacts) {
          <span fxFlex class="abs-text-overflow-ellipsis abs-bold">
            {{ getContactsName(draft.data?.contacts || []) }}
          </span>
        }
        <mat-icon matTooltip="Discard" fontIcon="mdi-cloud-off-outline" class="abs-margin-half-left" (click)="discardDraft($event)" />
        @if (draft?.data?.highValueAsset) {
          <mat-icon fontIcon="mdi-star" class="abs-margin-half-left" />
        }
        <span class="abs-margin-half-left">{{ draft.data?.owner?.initials }}</span>
        <mat-icon [fontIcon]="getIcon(draft.data?.type)" class="abs-margin-half-left" />
      </div>

      <div fxFlex class="subject abs-text-overflow-ellipsis abs-bold">DRAFT {{ draft.data?.subject ? ' - ' : '' }} {{ draft.data?.subject }}</div>
      <div fxFlex class="body-content">{{ strippedBody() }}</div>
      @if (discarding) {
        <mat-progress-bar mode="indeterminate" />
      }
    </div>
    <mat-divider />
  }
</app-custom-list-item>
