<form [formGroup]="formGroup" fxFlex fxLayout="column">
  @if (!selectedPortfolio()) {
    <div class="abs-margin-vertical abs-bold">Which product?</div>
    <app-custom-list mode="dense" fxFlex class="abs-scroll-auto">
      @for (portfolio of portfolios(); track portfolio) {
        @if (!portfolio.inactive) {
          <app-custom-list-item (click)="selectPortfolio(portfolio)">
            <div fxLayout="row" fxLayoutAlign="space-between" fxFlex>
              <div fxLayout="column">
                <div>{{ portfolio.shortName }}</div>
                <div class="abs-color-faded">{{ portfolio.ticker }}</div>
              </div>
              <div>AUM: {{ portfolio.totalPortfolio || 0 | currency }}</div>
            </div>
          </app-custom-list-item>
        }
      }
    </app-custom-list>
  } @else {
    <div fxLayout="row" class="abs-margin-vertical">
      <div fxLayout="row" fxFlex>
        <app-output label="Portfolio">{{ selectedPortfolio()!.shortName }}</app-output>
        @if (portfolios().length > 1) {
          <button mat-icon-button (click)="changePortfolio()">
            <mat-icon fontIcon="mdi-pencil" />
          </button>
        }
      </div>
    </div>
    @if (showResearchApproval()) {
      <div>
        <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-vertical">
          <div class="abs-margin-right abs-bold">Is this a research approval?</div>
          <mat-button-toggle-group formControlName="researchApproval" hideSingleSelectionIndicator>
            <mat-button-toggle [value]="true">Yes</mat-button-toggle>
            <mat-button-toggle [value]="false">No</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    }
  }
</form>
