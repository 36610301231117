import { KeyValue } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, input, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { SubscriptSizing } from '@angular/material/form-field';
import { DataFilter } from 'app/modules/common/framework/model/data-filter';
import { groupBy } from 'lodash-es';
import { distinctUntilChanged, map, startWith, switchMap, tap } from 'rxjs/operators';
import { customPortfolioEntity, PortfolioTO } from '../../model/portfolio.model';
import { PortfolioService } from '../../services/portfolio.service';

@Component({
  selector: 'app-portfolio-select',
  templateUrl: './portfolio-select.component.html',
  styleUrls: ['./portfolio-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortfolioSelectComponent implements OnInit {
  customPortfolioEntity = customPortfolioEntity;

  /**
   * All available portfolios.
   */
  private allPortfolios = signal<PortfolioTO[]>([]);

  /**
   * The portfolios after filtering by the search term.
   */
  filteredPortfolios = signal<PortfolioTO[]>([]);

  /**
   * The portfolios grouped by product line.
   */
  groupedPortfolios = computed(() => {
    return groupBy(this.filteredPortfolios(), (p) => p.productLine);
  });

  /**
   * Form control for the filter input for searching for portfolios.
   */
  searchPortfolioControl = new FormControl<string>('', { nonNullable: true });

  /**
   * Whether to allow multiple selection.
   */
  multiple = input<boolean>(false);

  /**
   * Form control to hold the selected value.
   */
  control = input.required<FormControl<number | number[] | null>>();

  /**
   * Whether to show the custom portfolio option.
   */
  customPortfolio = input<boolean>(false);

  /**
   * The subscript syzing to use for the field.
   */
  subscriptSizing = input<SubscriptSizing>('fixed');

  constructor(
    private portfolioService: PortfolioService,
    private destroyRef: DestroyRef,
  ) {}

  ngOnInit(): void {
    this.setupPortfolioSearch();
  }

  /**
   * Sets up the portfolio autocomplete search mechanism.
   */
  private setupPortfolioSearch(): void {
    // Load all portfolios from the server
    this.portfolioService
      .findAll('shortName,ASC', new DataFilter().equals('inactive', false, 'boolean'))
      .pipe(
        switchMap((p) => {
          if (this.customPortfolio()) {
            p.push(customPortfolioEntity as PortfolioTO);
          }

          this.allPortfolios.set(p);

          // Setup the filter to search for portfolios locally
          return this.searchPortfolioControl.valueChanges.pipe(
            takeUntilDestroyed(this.destroyRef),
            distinctUntilChanged(),
            map((search: string) => {
              if (search.length === 0) {
                return this.allPortfolios();
              } else if (this.allPortfolios()) {
                return this.allPortfolios().filter((p) => p.shortName.toLocaleLowerCase().includes(search.toLocaleLowerCase()));
              }

              return [];
            }),
            startWith(this.allPortfolios()),
          );
        }),
        tap((ps) => {
          this.filteredPortfolios.set(ps);
        }),
      )
      .subscribe();
  }

  /**
   * Sort the portfolio groups by the product line order attribute.
   */
  sortGroups(a: KeyValue<string, PortfolioTO[]>, b: KeyValue<string, PortfolioTO[]>): number {
    // Notice the key will always be a string, so we must compare to string 'null'
    if (a.key === 'null') {
      return 1;
    }

    if (b.key === 'null') {
      return -1;
    }

    return a.value[0].productLineOrder! - b.value[0].productLineOrder!;
  }
}
