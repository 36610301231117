import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SuperSearchPageableResult } from 'app/modules/common/framework/pagination/pageable';
import { PageableSuperSearchDataSource } from 'app/modules/common/framework/pagination/pageable-super-search-datasource';
import { Observable } from 'rxjs';
import { distinctUntilChanged, finalize, map, take, tap } from 'rxjs/operators';
import { LogDetailViewTO } from '../../../log/model/log.model';
import { LogService } from '../../../log/services/log.service';
import { SearchService } from '../../services/search.service';

/**
 * Responsible to show the log list and log detail on sidenav
 */
@Component({
  selector: 'app-search-log-sidenav',
  templateUrl: './search-log-sidenav.component.html',
  styleUrls: ['./search-log-sidenav.component.scss'],
})
export class SearchLogSidenavComponent implements OnInit {
  logData?: PageableSuperSearchDataSource<LogDetailViewTO>;
  logDetails: LogDetailViewTO | null = null;

  idtLog: number | null = null;
  searchTerm!: string;
  index: number | null = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private searchService: SearchService,
    private logService: LogService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    // Reexecute search if contact id or search term changes
    this.route.paramMap
      .pipe(
        map((params) => {
          return params.get('searchTerm') ?? '';
        }),
        distinctUntilChanged(),
        tap((searchTerm) => {
          this.searchTerm = searchTerm;
          this.searchLogs();
        }),
      )
      .subscribe();

    // Open details if a log is selected
    this.route.paramMap
      .pipe(
        map((params) => {
          return params.get('idtLog');
        }),
        map((idtLogStr) => {
          return idtLogStr ? Number(idtLogStr) : null;
        }),
        distinctUntilChanged(),
        tap((idtLog) => {
          this.idtLog = idtLog;

          if (this.idtLog) {
            this.showLogDetails();
          } else {
            this.logDetails = null;
          }
        }),
      )
      .subscribe();

    // Execute search if route was opened with filters
    this.route.queryParamMap
      .pipe(
        take(1),
        tap((params) => {
          this.index = Number(params.get('sidenav.logIndex'));
        }),
        tap(() => {
          if (this.index) {
            this.searchLogs();
          }
        }),
      )
      .subscribe();
  }

  /**
   * Handles a change in the search term. Navigates which will trigger a the search request with the new value.
   *
   * @param term the new search term
   */
  changeSearch(term: string) {
    if (term !== this.searchTerm) {
      this.router.navigate(['.', { searchTerm: term }], { relativeTo: this.route });
    }
  }

  /**
   * Searches for logs
   * @param term the term to search for
   */
  searchLogs(): void {
    // Set as undefined and force change detection to execute so the scroll is reset
    this.logData = undefined;
    this.cdr.detectChanges();

    const self = this;
    const limit = 25;
    const page = Math.floor((this.index || 0) / limit);
    const searchTerm = this.searchTerm ?? '*';

    this.logData = new (class extends PageableSuperSearchDataSource<LogDetailViewTO> {
      fetch(page: number, limit: number): Observable<SuperSearchPageableResult<LogDetailViewTO>> {
        return self.searchService.executeLogSearch(searchTerm, page, limit).pipe(
          finalize(() => {
            setTimeout(() => self.showLogDetails());
          }),
        );
      }
    })(limit, page);
  }

  /**
   * Shows log details when the click comes from outside the sidenav
   */
  showLogDetails(): void {
    if (this.idtLog) {
      this.logDetails = this.logService.getDetailsFromDatasource(this.idtLog, this.logData!);
    }
  }
}
