<app-custom-list-item class="log">
  @if (log(); as log) {
    <div fxFlex fxLayout="column" class="hide-overflow">
      <div fxFlex fxLayoutAlign="center center">
        <span class="abs-margin-half-right mat-caption">
          {{ log.reportedDate | date: 'MM/dd/yyyy' }}
        </span>
        @if (log.idtLog) {
          <span fxFlex class="abs-text-overflow-ellipsis abs-bold">
            {{ getContactsName(log.contacts) }}
          </span>
        }
        @if (log.highValueAsset) {
          <mat-icon fontIcon="mdi-star" class="abs-margin-half-left abs-secondary" matTooltip="High value asset" />
        }
        <span class="abs-margin-half-left">
          <span class="abs-bold">{{ log.authorInitials }}</span>
          @if (log.participants?.length || 0 > 0) {
            <span>, {{ log.participants! | pluck: 'initials' | joinMax: 3 }}</span>
          }
        </span>
        <mat-icon
          [fontIcon]="logIcons.getIconValue(log.type)"
          class="abs-margin-half-left abs-secondary"
          [matTooltip]="log.type | removeUnderline | titlecase"
        />
      </div>
      <div fxFlex class="subject abs-text-overflow-ellipsis">
        {{ log.subject }}
      </div>
      <div fxFlex class="body-content">
        <div fxFlex class="abs-color-faded">
          {{ strippedBody() }}
        </div>
        <div fxLayout="column" fxLayoutAlign="end">
          @if (log.opportunityCount > 0) {
            <mat-icon fontIcon="mdi-cash-100" class="abs-color-faded" [matTooltip]="log.opportunityCount + ' linked opportunities'" />
          }
        </div>
      </div>
    </div>
    <mat-divider />
  }
</app-custom-list-item>
