import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DuplicatedDocumentException } from '../../model/document.model';

/**
 * Dialog to list duplicated documents during an upload to confirm whether to upload them or not.
 */
@Component({
  selector: 'app-duplicated-documents-dialog',
  templateUrl: './duplicated-documents-dialog.component.html',
  styleUrl: './duplicated-documents-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DuplicatedDocumentsDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<DuplicatedDocumentsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DuplicatedDocumentException[],
  ) {}
}
