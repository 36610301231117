<h3>Status</h3>

<form [formGroup]="formGroup" fxLayout="column">
  <div fxLayout="row" class="abs-margin-bottom">
    <mat-button-toggle-group
      formControlName="status"
      hideSingleSelectionIndicator
      class="abs-margin-right"
      fxFlex="60"
      [ngClass]="{ error: formGroup.get('status')?.touched && formGroup.get('status')?.invalid }"
    >
      <mat-button-toggle fxFlex value="COLD">Cold</mat-button-toggle>
      <mat-button-toggle fxFlex value="WARM">Warm</mat-button-toggle>
      <mat-button-toggle fxFlex value="HOT">Hot</mat-button-toggle>
      <mat-button-toggle fxFlex value="BOILING">Boiling</mat-button-toggle>
    </mat-button-toggle-group>

    <mat-button-toggle-group
      formControlName="closedStatus"
      hideSingleSelectionIndicator
      fxFlex="40"
      (change)="closedStatusChange()"
      (click)="formGroup.get('closedStatus')?.enabled && onClosedStatusClick()"
    >
      <mat-button-toggle fxFlex value="WON">Won</mat-button-toggle>
      <mat-button-toggle fxFlex value="LOST">Lost</mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <mat-form-field fxFlex>
    <mat-label>Status comments</mat-label>
    <textarea matInput formControlName="statusComments"></textarea>
  </mat-form-field>

  @if (formGroup.controls.closedStatus.value === 'LOST') {
    <div class="abs-padding abs-dashed-box" fxLayout="column">
      <h3>Loss information</h3>
      <mat-form-field fxFlex>
        <mat-label>Reason</mat-label>
        <textarea matInput formControlName="lostReason"></textarea>
      </mat-form-field>
      <mat-form-field fxFlex>
        <mat-label>To whom</mat-label>
        <textarea matInput formControlName="lostWho"></textarea>
      </mat-form-field>
    </div>
  }

  @if (formGroup.controls.closedStatus.value === 'WON') {
    <div class="abs-padding abs-dashed-box" fxLayout="column">
      <h3>Win information</h3>
      <div>
        <mat-form-field (click)="fundingDatePicker.open()" class="abs-clickable">
          <mat-label>Funding date</mat-label>
          <input matInput [matDatepicker]="fundingDatePicker" formControlName="fundingDate" />
          <mat-datepicker-toggle matSuffix [for]="fundingDatePicker" />
          <mat-datepicker #fundingDatePicker />
        </mat-form-field>
      </div>
    </div>
  }

  <mat-form-field fxFlex>
    <mat-label>Known competition</mat-label>
    <textarea matInput formControlName="knownCompetition"></textarea>
  </mat-form-field>

  <h3>Timing</h3>
  <div class="abs-margin-bottom">
    <mat-button-toggle-group
      formControlName="probabilityTiming"
      hideSingleSelectionIndicator
      [ngClass]="{ error: formGroup.get('probabilityTiming')?.touched && formGroup.get('probabilityTiming')?.invalid }"
    >
      <mat-button-toggle [value]="opportunityTimingEnum.SHORT" matTooltip="Short">
        {{ opportunityTimingEnum.SHORT | probabilityTiming }}
      </mat-button-toggle>
      <mat-button-toggle [value]="opportunityTimingEnum.MEDIUM" matTooltip="Medium">
        {{ opportunityTimingEnum.MEDIUM | probabilityTiming }}
      </mat-button-toggle>
      <mat-button-toggle [value]="opportunityTimingEnum.LONG" matTooltip="Long">
        {{ opportunityTimingEnum.LONG | probabilityTiming }}
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <div fxLayout="row" fxLayoutAlign="space-between">
    <div fxLayout="row" fxFlex="60">
      <mat-form-field fxFlex>
        <mat-label>Size</mat-label>
        <input matInput formControlName="size" type="text" currencyMask [options]="{ prefix: '', align: 'left', precision: 0 }" />
        <span matTextPrefix>$&nbsp;</span>
      </mat-form-field>
      <button mat-raised-button class="input-button" type="button" (click)="addOneMillion()">+ 1MM</button>
      <button mat-raised-button class="input-button" type="button" (click)="addTenMillions()">+ 10MM</button>
    </div>

    <mat-form-field (click)="finalPitchPicker.open()" class="abs-clickable" fxFlex="25">
      <mat-label>Final pitch date</mat-label>
      <input matInput [matDatepicker]="finalPitchPicker" formControlName="finalPitch" />
      <mat-datepicker-toggle matSuffix [for]="finalPitchPicker" />
      <mat-datepicker #finalPitchPicker />
    </mat-form-field>
  </div>
</form>
