<h1 mat-dialog-title>Add lead</h1>
<div mat-dialog-content>
  <form id="leadForm" [formGroup]="formGroup" (submit)="formGroup.valid && save()">
    <mat-form-field fxFlex>
      <mat-label>Main lead</mat-label>
      <mat-select formControlName="idtLead">
        @for (user of users$ | async; track user) {
          <mat-option [value]="user.idtUser">
            <span [class.abs-color-faded]="user.inactive">{{ user.name }}</span>
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button type="submit" form="leadForm">Save</button>
</div>
