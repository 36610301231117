import { Directive, Input, ViewChild, WritableSignal, input, signal } from '@angular/core';
import { ContextMenuComponent, ContextMenuOption } from './context-menu.component';

/**
 * Abstract class to be implemented by entity context menu.
 */
@Directive()
export abstract class AbstractContextMenu<T> {
  /**
   * The text to display as the clickable area.
   */
  @Input({ required: true })
  displayText!: string;

  /**
   * The id of the entity with getter and setter.
   */
  private _id?: number;

  @Input()
  set id(value: number | undefined) {
    this._id = value;
    this.entity.set(null);
    this.loadOptions();
  }

  get id(): number | undefined {
    return this._id;
  }

  /**
   * Whether to disable the context menu and render as a simple text.
   */
  disabled = input<boolean>(false);

  /**
   * The options to show in the context menu.
   */
  options: WritableSignal<ContextMenuOption[]> = signal([]);

  /**
   * The full entity object.
   */
  entity: WritableSignal<T | null> = signal<T | null>(null);

  /**
   * Reference to the menu instance
   */
  @ViewChild('menu')
  protected menu!: ContextMenuComponent;

  /**
   * Load the options to be shown.
   */
  protected abstract loadOptions(): void;

  /**
   * Load the entity from the server.
   */
  abstract loadEntity(): void;
}
