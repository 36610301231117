import { User } from '../../user/user.model';

export interface BasePortfolio {
  idtPortfolio: number;
  shortName: string;
  ticker: string;
  productLine: string | null;
}

/**
 * Base data for portfolios.
 */
export interface AbstractPortfolioTO extends BasePortfolio {
  fullName: string;
  ticker: string;
  closed: boolean;
  inactive: boolean;
  key: string;
  idtProductLine?: number;
  productLineOrder?: number;
}

/**
 * Represents the Portfolio
 */
export interface PortfolioTO extends AbstractPortfolioTO {
  manager: User;
  portfolioClasses: PortfolioClass[];
}

/**
 * Hurdle data.
 */
export interface HurdleData {
  hurdleType?: HurdleType;
  hurdleValue?: number;
  hurdleIndex?: string;
}

/**
 * A portfolio class.
 */
export interface PortfolioClass extends HurdleData {
  idtClass: number;
  name: string;
  idtPortfolio: number;
  portfolio: string;
  managementFee?: number;
  managementFeeWaived?: number;
  incentiveFee?: number;
  idtTerm: number | null;
  termName?: string;
  term?: TermViewTO;
  inactive: boolean;
  closed: boolean;
  type: ClassTypeEnum;
  idtCurrency: number;
  currencyCode: string;
  currency: string;
  hotIssueEligibility: ClassHotIssueEligibilityEnum;
  returnBasis: ReturnBasisEnum;
  idtParent?: number;
  parentName?: string;
  citcoId?: string;
  citcoSmaId?: string;
}

export enum ReturnBasisEnum {
  NAV = 'NAV',
  MARKET = 'MARKET',
}

export enum HurdleType {
  FIXED_VALUE = 'FIXED_VALUE',
  NO_HURDLE = 'NO_HURDLE',
  INDEX = 'INDEX',
}

/**
 * Represents the DTO to hold only the portfolio ticker and id.
 */
export interface PortfolioIdAndTicker {
  idtPortfolio: number;
  ticker: string;
}

/**
 * Mocked portfolio entity used for "custom portfolio" option.
 */
export const customPortfolioEntity: BasePortfolio = {
  idtPortfolio: 0,
  shortName: 'Custom portfolio',
  ticker: 'CUSTOM PORTFOLIO',
  productLine: null,
};

/**
 * Data when searching for portfolios with classes.
 */
export interface PortfolioWithClassDTO {
  idtPortfolio: number;
  name: string;
  classes: PortfolioClassDTO[];
}

/**
 * Class data when searching for portfolios with classes.
 */
export interface PortfolioClassDTO {
  idtClass: number;
  className: string;
  classInactive: boolean;
}

/**
 * Bias data.
 */
export interface BiasViewTO {
  idtBias: number;
  name: string;
}

/**
 * Domicile data.
 */
export interface DomicileViewTO {
  idtDomicile: number;
  name: string;
}

/**
 * Portfolio legal entity data.
 */
export interface PortfolioEntityViewTO {
  idtPortfolioEntity: number;
  name: string;
}

/**
 * Absolute company data.
 */
export interface AbsoluteCompanyViewTO {
  idtCompany: number;
  name: string;
}

export interface ProductLineViewTO {
  idtProductLine: number;
  name: string;
  order: string;
}

/**
 * Portfolio details.
 */
export interface PortfolioDetailsViewTO extends AbstractPortfolioTO {
  idtDomicile: number;
  idtDomicileOffshore: number;
  domicile: string;
  domicileOffshore: string;
  portfolioEntity: string;
  provider: PortfolioProviderEnum;
  providerProductId: string;
  idtBias: number;
  bias: string;
  quarterlyLetters: boolean;
  monthlyFactsheets: boolean;
  idtDefaultClass: number;
  defaultClass: string;
  manager: string;
  idtPortfolioEntity: number;
  type: number;
  structure: number;
  erisaStatus: number;
  hotIssueEligType: number;
  hotIssueEligValue: number;
  idtAdministratorCompany: number;
  administratorCompany: string;
  idtAuditorCompany: number;
  auditorCompany: string;
  idtLegalCompany: number;
  legalCompany: string;
  idtCustodianCompany: number;
  custodianCompany: string;
  comments: string;
}

/**
 * Interface to represent a select option.
 */
export interface Option {
  label: string;
  order: number;
  abbreviation?: string;
}

/**
 * Portfolio type options.
 */
export const PortfolioType: Record<number, Option | undefined> = {
  1: { label: 'Commingled', order: 2 },
  2: { label: 'Customized Fund of One', order: 3 },
  3: { label: 'Discretionary Separate Account', order: 4 },
  4: { label: 'Non-Discretionary Separate Account', order: 5 },
  5: { label: 'Advisory', order: 1 },
};

/**
 * Portfolio structure options.
 */
export const PortfolioStructure: Record<number, Option | undefined> = {
  1: { label: '3c1 Offshore Fund', order: 1 },
  2: { label: '3c7 Offshore Fund', order: 2 },
  3: { label: '3c1 US LP', order: 3 },
  4: { label: '3c7 US LP', order: 4 },
  5: { label: 'Other', order: 6 },
  6: { label: 'RIC (Registered Investment Company)', order: 5 },
};

/**
 * Portfolio erisa status options.
 */
export const PortfolioErisaStatus: Record<number, Option | undefined> = {
  1: { label: '25% Limit', order: 1 },
  2: { label: 'ERISA-Dedicated Fund', order: 2 },
};

/**
 * Portfolio hot issue eligibility type options.
 */
export const PortfolioHotIssueEligibility: Record<number, Option | undefined> = {
  1: { label: 'Restricted', order: 1 },
  2: { label: 'Unrestricted', order: 2 },
  3: { label: 'Restricted (with max X% hot issue shares)', order: 3 },
};

/**
 * Portfolio provider options.
 */
export enum PortfolioProviderEnum {
  CITCO = 'CITCO',
  ISIN = 'ISIN',
}

/**
 * Term data.
 */
export interface TermViewTO {
  idtTerm: number;
  name: string;
  redemptionFreqUnit?: string;
  redemptionOffsetValue: number;
  noticeType?: number;
  managementFee?: number;
  managementFeeWaived: boolean;
  incentiveFee?: number;
  description?: string;
  lockups: TermLockupViewTO[];
  earlyExitValue?: number;
  earlyExitWaived?: boolean;
  hurdleIndexCode?: string;
  hurdleIndexName?: string;
  hurdleValue?: number;
  redemptionFee?: number;
  redemptionFeeWaived: boolean;
  placementFee: number;
  placementFeeWaived: boolean;
  lastModifiedBy: string;
  lastModification: string;
  frequencyType?: number;
  offsetTemporalUnit?: number;
  redemptionNotice?: number;
  investorLevelGateValue?: number;
  expenseCapFee?: number;
  expenseCapFeeWaived: boolean;
  distributionServiceFee?: number;
  distributionServiceFeeWaived?: boolean;
  salesLoadFee?: number;
  salesLoadFeeWaived?: boolean;
  redemptionComment?: string;
  gateValue?: number;
  gateComment?: string;
  sidePocketValue?: number;
  sidePocketComment?: string;
  auditHoldbackValue?: number;
  auditHoldbackComment?: string;
  subscriptionComment?: string;
  subscriptionFreqUnit?: string;
  subscriptionFreqType?: number;
  subscriptionOffsetValue?: number;
  subscriptionOffsetTemporalUnit?: number;
  subscriptionNotice?: number;
  subscriptionNoticeType?: number;
  subscriptionInitialAmount?: number;
  subscriptionSubsequentAmount?: number;
  managementFeeComment?: string;
  idtRedemptionFrequency?: number;
  idtSubscriptionFrequency?: number;
  idtHurdleIndex?: number;
}

export enum TermTemporalUnitEnum {
  DAYS = 1,
  WEEKS,
  MONTHS,
  YEARS,
}

export const TermTemporalUnitDatatype: Record<number, Option | undefined> = {
  1: { label: 'Days', order: 1 },
  2: { label: 'Weeks', order: 2 },
  3: { label: 'Months', order: 3 },
  4: { label: 'Years', order: 5 },
};

export enum TermFrequencyTypeEnum {
  CALENDAR = 1,
  ANNIVERSARY,
}

export const TermFrequencyTypeDatatype: Record<number, Option | undefined> = {
  1: { label: 'Calendar', order: 1, abbreviation: 'C' },
  2: { label: 'Anniversary', order: 2, abbreviation: 'A' },
};

export enum TermNoticeType {
  CALENDAR_DAYS = 1,
  BUSINESS_DAYS,
}

export const TermNoticeTypeDatatype: Record<number, Option | undefined> = {
  1: { label: 'Calendar Days', order: 1, abbreviation: 'C' },
  2: { label: 'Business Days', order: 2, abbreviation: 'B' },
};

export enum TermLockupModeEnum {
  PERIOD = 1,
  ABSOLUTE_DATE,
}

export const TermLockupModeDatatype: Record<number, Option | undefined> = {
  1: { label: 'Period', order: 1 },
  2: { label: 'Absolute Date', order: 2 },
};

export enum ClassTypeEnum {
  OFFSHORE = 'OFFSHORE',
  LP = 'LP',
}

export enum ClassHotIssueEligibilityEnum {
  UNRESTRICTED = 'UNRESTRICTED',
  RESTRICTED = 'RESTRICTED',
}

export const TermFrequency = [
  { idtTermFrequency: 1, name: 'Daily', order: 1, offsetUnit: 'days', offsetMaxValue: 0 },
  { idtTermFrequency: 2, name: 'Weekly', order: 2, offsetUnit: 'days', offsetMaxValue: 6 },
  { idtTermFrequency: 3, name: 'Bi-Monthly', order: 3, offsetUnit: 'days', offsetMaxValue: 13 },
  { idtTermFrequency: 4, name: 'Monthly', order: 4, offsetUnit: 'days', offsetMaxValue: 27 },
  { idtTermFrequency: 5, name: 'Quarterly', order: 5, offsetUnit: 'months', offsetMaxValue: 2 },
  { idtTermFrequency: 6, name: 'Semi-Annual', order: 6, offsetUnit: 'months', offsetMaxValue: 5 },
  { idtTermFrequency: 7, name: 'Annual', order: 7, offsetUnit: 'months', offsetMaxValue: 11 },
  { idtTermFrequency: 8, name: 'Rolling 2 Years', order: 8, offsetUnit: 'months', offsetMaxValue: 11 },
  { idtTermFrequency: 9, name: 'Rolling 3 Years', order: 9, offsetUnit: 'months', offsetMaxValue: 11 },
  { idtTermFrequency: 10, name: 'Rolling 4 Years', order: 10, offsetUnit: 'months', offsetMaxValue: 11 },
];

/**
 * Term lockup data.
 */
export interface TermLockupViewTO {
  idtTermLockup: number;
  idtTerm: number;
  valueText: string;
  value: number;
  waived: boolean;
  hardLock: boolean;
  fee: number;
  comment?: string;
  date?: string;
  unit: number;
}

export interface TermUpdateDTO {
  name: string;
  description: string;
  fee: {
    management: { value: number | null; waived: boolean };
    placement: { value: number | null; waived: boolean };
    redemption: { value: number | null; waived: boolean };
    incentive: { value: number | null; hurdleRate: number | null; idtHurdleIndex: number | null };
    comment: string;
  };
  redemption: {
    frequency: { idtTermFrequency: number | null; type: number | null; offsetPeriod: number | null };
    notice: { value: number | null; type: number | null };
    investorLevelGate: number | null;
    earlyExitFee: { value: number | null; waived: boolean };
    comment: string;
    gate: { value: number | null; comment: string };
    sidePocket: { value: number | null; comment: string };
    auditHoldback: { value: number | null; comment: string };
  };
  subscription: {
    frequency: { idtTermFrequency: number | null; type: number | null; offsetPeriod: number | null };
    notice: { value: number | null; type: number | null };
    initialAmount: number | null;
    subsequentAmount: number | null;
    comment: string;
  };
  lockups: {
    lockupDate: string | null;
    fee: { value: number | null; waived: boolean };
    feeHardLock: boolean;
    feeComment: string;
    lockupTimeUnit: number | null;
    lockupPeriod: number | null;
    lockupMode: number | null;
  }[];
}

/**
 * Data needed when sending request create/update a portfolio class.
 */
export interface PortfolioClassUpdateDTO {
  name: string;
  type: number;
  inactive: boolean;
  situation: number;
  idtCurrency: number;
  hotIssueEligibility: number;
  idtTerm: number;
  returnBasis: number;
  citcoId: string;
  citcoSmaId: string;
  idtParent: number | null;
}
