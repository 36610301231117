import { ChangeDetectionStrategy, Component, DestroyRef, Input, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { interval } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { Draft } from '../../model/draft.model';
import { DraftService } from '../../services/draft.service';

/**
 * Draft component: saves the draft at each interval time and shows the user a feedback.
 */
@Component({
  selector: 'app-draft',
  templateUrl: './draft.component.html',
  styleUrls: ['./draft.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DraftComponent implements OnInit {
  constructor(
    private service: DraftService,
    private destroyRef: DestroyRef,
  ) {}

  /**
   * The data to be saved as a draft.
   */
  @Input() data?: Draft<any>;

  /**
   * The current status of the draft entity.
   */
  status = signal<'saving' | 'saved' | 'error' | null>(null);

  // Create an Observable that will publish a value on an interval
  private draftInterval = interval(5000);

  /**
   * Starts interval to listen to draft changes.
   */
  ngOnInit(): void {
    this.draftInterval
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        map(() => JSON.stringify(this.data)),
        distinctUntilChanged(),
      )
      .subscribe(() => this.saveDraft());
  }

  /**
   * Save draft.
   * @param toSave
   */
  private saveDraft() {
    if (this.data) {
      this.status.set('saving');
      this.service.save(this.data).subscribe({
        next: (draft) => {
          if (this.data && !this.data?.id) {
            this.data.id = draft.id;
            this.data.idtUser = draft.idtUser;
          }
          this.status.set('saved');
        },
        error: () => {
          this.status.set('error');
        },
      });
    }
  }
}
