import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DialogService } from 'app/modules/common/framework/dialog/dialog.service';
import { Pageable } from 'app/modules/common/framework/pagination/pageable';
import { PageableSuperSearchDataSource } from 'app/modules/common/framework/pagination/pageable-super-search-datasource';
import { EventQueueService } from 'app/modules/common/framework/services/event-queue.service';
import { environment } from 'environments/environment';
import { DateTime } from 'luxon';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Document } from '../../document/model/document.model';
import { OpportunityEventEnum } from '../../opportunity/opporutnity-events.enum';
import { LogEventEnum } from '../LogEventEnum';
import { Log, LogContactsDTO, LogDetailViewTO, LogInputDTO, LogReportData, LogTotal, LogTypeEnum } from '../model/log.model';

@Injectable({
  providedIn: 'root',
})
export class LogService {
  constructor(
    private http: HttpClient,
    private eventQueueService: EventQueueService,
    private dialogService: DialogService,
  ) {}

  /**
   * Creates a new log in the server.
   *
   * @param data the log data, with attached entities and participants
   * @returns an observable that resolves to the log created
   */
  post(data: LogInputDTO): Observable<Log> {
    return this.http.post<Log>(`${environment.apiUrl}/log`, data).pipe(
      tap(() => this.eventQueueService.dispatch(LogEventEnum.logSaved)),
      tap(() => {
        if (data.idtOpportunity) {
          this.eventQueueService.dispatch(OpportunityEventEnum.opportunitySaved);
        }
      }),
    );
  }

  /**
   * Updates a log in the server.
   *
   * @param data the log data, with attached entities and participants
   * @param id the id of the log to update
   * @returns an observable that resolves to the log created
   */
  put(data: LogInputDTO, id: number): Observable<Log> {
    return this.http.put<Log>(`${environment.apiUrl}/log/${id}`, data).pipe(tap(() => this.eventQueueService.dispatch(LogEventEnum.logSaved)));
  }

  /**
   * Deletes a log.
   *
   * @param idtLog the id of the log to be deleted
   * @returns an observable that resolves to void after the server responds
   */
  delete(idtLog: number): Observable<void> {
    return this.http.delete<void>(`${environment.apiUrl}/log/${idtLog}`).pipe(
      catchError((err) => {
        this.dialogService.showError(err.error?.message || "Couldn't delete the log");
        return throwError(err);
      }),
      tap(() => this.eventQueueService.dispatch(LogEventEnum.logSaved)),
    );
  }

  /**
   * Get detailed data for a single log
   *
   * @param id the id of the log
   * @returns the log found
   */
  getDetails(id: number): Observable<Log> {
    return this.http.get<Log>(`${environment.apiUrl}/log/${id}/details`);
  }

  /**
   * Get a single log data.
   *
   * @param idtLog the log id
   * @returns an observable that emits the log found when the server responds
   */
  get(idtLog: number): Observable<LogDetailViewTO> {
    return this.http.get<LogDetailViewTO>(`${environment.apiUrl}/log/${idtLog}`);
  }

  /**
   * Find a single email log by id.
   *
   * @param id the id of the emil log
   * @returns an observable that resolves to the log found from the server
   */
  getEmail(id: number): Observable<Log> {
    return this.http.get<Log>(`${environment.apiUrl}/log/email/${id}`);
  }

  /**
   * Gets the details of specific log
   * @param id The log id
   * @param type the type of log
   * @param logData the data of a elastic search result
   * @returns the log or null if not match
   */
  getDetailsFromDatasource(id: number, logData: PageableSuperSearchDataSource<LogDetailViewTO>): LogDetailViewTO | null {
    const logDetail = logData?.cachedData?.find((log) => log.idtLog === id);

    return logDetail ?? null;
  }

  /**
   * Generate the log report, opening the PDF for download in a new window.
   *
   * @param startDate the start of the logs period
   * @param endDate the end of the logs period
   * @param [idtUsers=[]] list of user ids to filter for
   * @param [idtContacts=[]] list of contact ids to filter for
   */
  downloadReport(startDate: DateTime, endDate: DateTime, idtUsers: number[] = [], idtContacts: number[] = []): Observable<Blob> {
    const params = {
      startDate: startDate.toISODate(),
      endDate: endDate.toISODate(),
      idtUsers,
      idtContacts,
    };
    return this.http.get<Blob>(`${environment.apiUrl}/log/report`, { params, responseType: 'blob' as 'json' });
  }

  /**
   * Get log report data from the server.
   */
  getLogReportData(
    startDate: DateTime,
    endDate: DateTime,
    indTypes: LogTypeEnum[],
    idtUsers: number[] = [],
    idtContacts: number[] = [],
  ): Observable<LogReportData> {
    const params = {
      startDate: startDate.toISODate(),
      endDate: endDate.toISODate(),
      indTypes,
      idtUsers,
      idtContacts,
    };

    return this.http.get<LogReportData>(`${environment.apiUrl}/log/report`, { params });
  }

  /**
   * Gets a list of Logs for the provided filters.
   *
   * @param idtClient client id
   * @param idtExtRep external rep id
   * @param page the page to get
   * @param limit the size of the page
   * @returns an observable that resolves to the page receivied from the server
   */
  findLogs(
    idtContact?: number,
    type?: LogTypeEnum,
    idtOpportunities?: number | number[],
    page: number = 0,
    limit: number = 10,
  ): Observable<Pageable<LogDetailViewTO>> {
    return this.http.get<Pageable<LogDetailViewTO>>(`${environment.apiUrl}/log/list/all`, {
      params: {
        idtContact: idtContact || '',
        idtOpportunities: idtOpportunities || '',
        type: type || '',
        page,
        size: limit,
      },
    });
  }

  /**
   * Gets a total of logs list by type for the provided filters.
   * @param idtClient client id
   * @param idtExtRep external rep idfor
   */
  findLogsTotals(idtContact?: number, idtOpportunities?: number | number[]): Observable<LogTotal[]> {
    return this.http.get<LogTotal[]>(`${environment.apiUrl}/log/list/total`, {
      params: {
        idtContact: idtContact ? idtContact.toString() : '',
        idtOpportunities: idtOpportunities || '',
      },
    });
  }

  /**
   * Get the contacts referenced in the provided log.
   *
   * @param idtLog the log id
   * @returns object with the list of contacts and related contacts
   */
  getContacts(idtLog: number): Observable<LogContactsDTO> {
    return this.http.get<LogContactsDTO>(`${environment.apiUrl}/log/${idtLog}/contact`);
  }

  /**
   * Associate a log and an opportunity.
   *
   * @param idtLog the log id
   * @param idtOpportunity the opportunity id
   * @returns an observable that emits void when the server responds
   */
  associateOpportunity(idtLog: number, idtOpportunity: number): Observable<void> {
    return this.http
      .post<void>(`${environment.apiUrl}/log/${idtLog}/opportunity`, { idtOpportunity })
      .pipe(tap(() => this.eventQueueService.dispatch(OpportunityEventEnum.opportunitySaved)));
  }

  /**
   * Remove the association between a log and an opportunity.
   *
   * @param idtLog the log id
   * @param idtOpportunity the opportunity id
   * @returns an observable that emits void when the server responds
   */
  removeOpportunityAssociation(idtLog: number, idtOpportunity: number): Observable<void> {
    return this.http
      .delete<void>(`${environment.apiUrl}/log/${idtLog}/opportunity`, { params: { idtOpportunity } })
      .pipe(tap(() => this.eventQueueService.dispatch(OpportunityEventEnum.opportunitySaved)));
  }

  /**
   * Get all documents linked to the provided log.
   *
   * @param idtLog the log id
   * @returns an observable that emits the list of documents found when the server responds
   */
  getLinkedDocuments(idtLog: number): Observable<Document[]> {
    return this.http.get<Document[]>(`${environment.apiUrl}/log/${idtLog}/document`);
  }
}
