import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { DataFilter } from 'app/modules/common/framework/model/data-filter';
import { environment } from 'environments/environment';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { PortfolioAllocation } from '../model/portfolio-allocation.model';
import {
  AbsoluteCompanyViewTO,
  BiasViewTO,
  DomicileViewTO,
  PortfolioClassUpdateDTO,
  PortfolioDetailsViewTO,
  PortfolioEntityViewTO,
  PortfolioTO,
  PortfolioWithClassDTO,
  ProductLineViewTO,
  TermUpdateDTO,
  TermViewTO,
} from '../model/portfolio.model';

/**
 * Service to call the api for a Portfolio CRUD and business rule
 */
@Injectable({
  providedIn: 'root',
})
export class PortfolioService {
  constructor(private http: HttpClient) {}

  /**
   * Gets the Portfolio data from api
   *
   * @returns Portfolio
   */
  findAll(sort: string = 'idtPortfolio,asc', filter: DataFilter): Observable<PortfolioTO[]> {
    return this.http.get<PortfolioTO[]>(`${environment.apiUrl}/portfolio?filter=${filter.encodeURIComponent()}`, {
      params: { sort },
    });
  }

  /**
   * Get all portfolios with associated classes.
   *
   * @param sort the column and direction to sort by
   * @param portfolioFilter the filters to apply to the portfolio search
   * @param classFilter the filters to apply when loading the portfolio classes
   * @returns the list of portfolios found wrapped in an observable
   */
  findAllWithClasses(sort: string = 'idtPortfolio,asc', portfolioFilter = '', classFilter = ''): Observable<PortfolioTO[]> {
    return this.http.get<PortfolioTO[]>(`${environment.apiUrl}/portfolio/with-classes`, {
      params: { sort, portfolioFilter, classFilter },
    });
  }

  /**
   * Find list of portfolios with associated classes.
   *
   * @param filter filters to apply to the query
   * @returns list of portfolios with the associated classes
   */
  findWithClasses(filter: DataFilter): Observable<PortfolioWithClassDTO[]> {
    return this.http.get<PortfolioWithClassDTO[]>(`${environment.apiUrl}/portfolio/classes`, {
      params: { filter: filter.encode() },
    });
  }

  /**
   * Return all portfolios allocations.
   *
   * @param date the reference date to calculate the AUMs
   * @param includeZeroBalance whether to include portfolios with zero AUM
   * @returns allocation data
   */
  findAllocations(date: DateTime | null, includeZeroBalance = false): Observable<PortfolioAllocation[]> {
    return this.http.get<PortfolioAllocation[]>(`${environment.apiUrl}/portfolio/allocations`, {
      params: {
        date: date != null ? date.toISODate() : '',
        includeZeroBalance,
      },
    });
  }

  /**
   * Get a single portfolio data.
   *
   * @param idtPortfolio the portfolio id
   * @returns an observable that emits the portfolio when the server responds
   */
  getById(idtPortfolio: number): Observable<PortfolioTO> {
    return this.http.get<PortfolioTO>(`${environment.apiUrl}/portfolio/${idtPortfolio}`);
  }

  /**
   * Get all bias options.
   *
   * @returns observable that emits all options
   */
  getBiasOptions(): Observable<BiasViewTO[]> {
    return this.http.get<BiasViewTO[]>(`${environment.apiUrl}/integration/absolute/portfolio/bias`);
  }

  /**
   * Get all domicile options.
   *
   * @returns observable that emits all options
   */
  getDomicileOptions(): Observable<DomicileViewTO[]> {
    return this.http.get<DomicileViewTO[]>(`${environment.apiUrl}/integration/absolute/portfolio/domicile`);
  }

  /**
   * Get all portfolio legal entity options.
   *
   * @returns observable that emits all options
   */
  getPortfolioEntityOptions(): Observable<PortfolioEntityViewTO[]> {
    return this.http.get<PortfolioEntityViewTO[]>(`${environment.apiUrl}/integration/absolute/portfolio/portfolio-entity`);
  }

  /**
   * Get custodian options.
   *
   * @param searchTerm term to filter for
   * @returns observable that emits the options found
   */
  getCustodianOptions(searchTerm: string): Observable<AbsoluteCompanyViewTO[]> {
    const filter = new DataFilter().equals('custodian', true, 'boolean').like('name', `%${searchTerm}%`, 'string').encodeURIComponent();

    return this.http.get<AbsoluteCompanyViewTO[]>(`${environment.apiUrl}/integration/absolute/portfolio/company?filter=${filter}`);
  }

  /**
   * Get auditor options.
   *
   * @param searchTerm term to filter for
   * @returns observable that emits the options found
   */
  getAuditorOptions(searchTerm: string): Observable<AbsoluteCompanyViewTO[]> {
    const filter = new DataFilter().equals('auditor', true, 'boolean').like('name', `%${searchTerm}%`, 'string').encodeURIComponent();

    return this.http.get<AbsoluteCompanyViewTO[]>(`${environment.apiUrl}/integration/absolute/portfolio/company?filter=${filter}`);
  }

  /**
   * Get legal options.
   *
   * @param searchTerm term to filter for
   * @returns observable that emits the options found
   */
  getLegalOptions(searchTerm: string): Observable<AbsoluteCompanyViewTO[]> {
    const filter = new DataFilter().equals('legal', true, 'boolean').like('name', `%${searchTerm}%`, 'string').encodeURIComponent();

    return this.http.get<AbsoluteCompanyViewTO[]>(`${environment.apiUrl}/integration/absolute/portfolio/company?filter=${filter}`);
  }

  /**
   * Get administrator options.
   *
   * @param searchTerm term to filter for
   * @returns observable that emits the options found
   */
  getAdministratorOptions(searchTerm: string): Observable<AbsoluteCompanyViewTO[]> {
    const filter = new DataFilter().equals('administrator', true, 'boolean').like('name', `%${searchTerm}%`, 'string').encodeURIComponent();

    return this.http.get<AbsoluteCompanyViewTO[]>(`${environment.apiUrl}/integration/absolute/portfolio/company?filter=${filter}`);
  }

  /**
   * Get all product line options.
   *
   * @returns observable that emits the options found
   */
  getProductLineOptions(): Observable<ProductLineViewTO[]> {
    return this.http.get<ProductLineViewTO[]>(`${environment.apiUrl}/integration/absolute/portfolio/product-line`);
  }

  /**
   * Get a portfolio detailed data by it's id.
   *
   * @param idtPortfolio the portfolio id
   * @returns an observable that emits the portfolio detailed data
   */
  getPortfolioDetails(idtPortfolio: number): Observable<PortfolioDetailsViewTO> {
    return this.http.get<PortfolioDetailsViewTO>(`${environment.apiUrl}/portfolio/${idtPortfolio}/details`);
  }

  /**
   * Send request to update a portfolio.
   *
   * @param idtPortfolio the portfolio id
   * @param data the new portfolio data to be saved
   * @returns an observable that emits void when the server responds
   */
  updatePortfolio(idtPortfolio: number, data: any): Observable<void> {
    return this.http.put<void>(`${environment.apiUrl}/integration/absolute/portfolio/${idtPortfolio}`, data);
  }

  /**
   * Update a portfolio import key.
   *
   * @param idtPortfolio the portfolio id
   * @param key the new key value
   * @returns an observable that emits void when the server responds
   */
  updatePortfolioKey(idtPortfolio: number, key: string): Observable<void> {
    return this.http.put<void>(`${environment.apiUrl}/portfolio/${idtPortfolio}/key`, { key });
  }

  /**
   * Get all terms for the provided portfolio.
   *
   * @param idtPortfolio the portfolio id
   * @returns an observable that emits the terms found
   */
  getTerms(idtPortfolio: number): Observable<TermViewTO[]> {
    return this.http.get<TermViewTO[]>(`${environment.apiUrl}/portfolio/${idtPortfolio}/term`);
  }

  /**
   * Get a term by its id.
   *
   * @param idtTerm the term id
   * @returns an observable that emits the term data
   */
  getTerm(idtTerm: number) {
    return this.http.get<TermViewTO>(`${environment.apiUrl}/portfolio/term/${idtTerm}`);
  }

  /**
   * Delete a term.
   *
   * @param idtPortfolio the term's portfolio
   * @param idtTerm the term's id
   * @returns an observable that emits void when the server responds
   */
  deleteTerm(idtPortfolio: number, idtTerm: number) {
    return this.http.delete<void>(`${environment.apiUrl}/integration/absolute/portfolio/${idtPortfolio}/term/${idtTerm}`);
  }

  /**
   * Create a new term.
   *
   * @param idtPortfolio the protfolio id
   * @param data the term data
   * @returns an observable that emits the new term id
   */
  createTerm(idtPortfolio: number, data: TermUpdateDTO) {
    return this.http.post<number>(`${environment.apiUrl}/integration/absolute/portfolio/${idtPortfolio}/term`, data);
  }

  /**
   * Update an existing term.
   *
   * @param idtPortfolio the portfolio id
   * @param idtTerm the term id
   * @param data the term data to be saved
   * @returns an observable that emits void when the server responds
   */
  updateTerm(idtPortfolio: number, idtTerm: number, data: TermUpdateDTO) {
    return this.http.put<void>(`${environment.apiUrl}/integration/absolute/portfolio/${idtPortfolio}/term/${idtTerm}`, data);
  }

  /**
   * Creates a new portfolio class.
   *
   * @param idtPortfolio the portfolio id the class belongs to
   * @param data the class/series data
   * @returns an observable that emits the created class id when the server responds
   */
  createClass(idtPortfolio: number, data: PortfolioClassUpdateDTO) {
    return this.http.post<number>(`${environment.apiUrl}/integration/absolute/portfolio/${idtPortfolio}/class`, data);
  }

  /**
   * Updates an existing portfolio class.
   *
   * @param idtPortfolio the portfolio id the class belongs to
   * @param idtClass the class/series id
   * @param data the class/series data to be saved
   * @returns an observable that emits the created class id when the server responds
   */
  updateClass(idtPortfolio: number, idtClass: number, data: PortfolioClassUpdateDTO) {
    return this.http.put<number>(`${environment.apiUrl}/integration/absolute/portfolio/${idtPortfolio}/class/${idtClass}`, data);
  }
}

/**
 * Resolve service to get a portfolio data based on the route id.
 */
export const portfolioResolver: ResolveFn<PortfolioTO> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  portfolioService: PortfolioService = inject(PortfolioService),
) => {
  return portfolioService.getById(Number(route.paramMap.get('idtPortfolio')));
};

/**
 * Resolve service to get a portfolio detailed data based on the route id.
 */
export const portfolioDetailsResolver: ResolveFn<PortfolioDetailsViewTO> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  portfolioService: PortfolioService = inject(PortfolioService),
) => {
  return portfolioService.getPortfolioDetails(Number(route.paramMap.get('idtPortfolio')));
};
