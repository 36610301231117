import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TrackerService } from 'app/modules/common/business/tracker/services/tracker.service';
import { PageableSuperSearchDataSource } from 'app/modules/common/framework/pagination/pageable-super-search-datasource';
import { Subscription } from 'rxjs';
import { ContactTypeEnum } from '../../../contact/model/contact.model';
import { LogDetailViewTO } from '../../model/log.model';

/**
 * Component to navigate between a list of log on sidenav
 */
@Component({
  selector: 'app-log-list-navigator',
  templateUrl: './log-list-navigator.component.html',
  styleUrls: ['./log-list-navigator.component.scss'],
})
export class LogListNavigatorComponent {
  constructor(private trackerService: TrackerService) {}

  /**
   * The pageable items to navigate
   */
  @Input()
  items!: PageableSuperSearchDataSource<LogDetailViewTO>;

  /**
   * The current item to show its data
   */
  private _current!: LogDetailViewTO;

  @Input()
  set current(value: LogDetailViewTO) {
    this._current = value;
    this.handlePreviousAndNextData();
  }

  get current(): LogDetailViewTO {
    return this._current;
  }

  /**
   * Get the name (10 characters) for the first company related to the previous log.
   */
  get previousCompany(): string | undefined {
    let name = (this.previous?.contacts.find((c) => c.type === ContactTypeEnum.COMPANY) || this.previous?.contacts[0])?.name;

    if (name && name.length > 10) {
      name = name.substring(0, 10) + '...';
    }

    return name;
  }

  /**
   * Get the name (10 characters) for the first company related to the next log.
   */
  get nextCompany(): string | undefined {
    let name = (this.next?.contacts.find((c) => c.type === ContactTypeEnum.COMPANY) || this.next?.contacts[0])?.name;

    if (name && name.length > 10) {
      name = name.substring(0, 10) + '...';
    }

    return name;
  }

  /**
   * The previous content that can be selected
   */
  previous!: LogDetailViewTO | null;

  /**
   * The next content that can be selected
   */
  next!: LogDetailViewTO | null;

  /**
   * Subscription to the loading indicator from the data source.
   */
  private loadingSubscription?: Subscription;

  /**
   * Emits the content of data to be shown
   */
  @Output()
  body = new EventEmitter<LogDetailViewTO>();

  /**
   * Defines the content of previous and next item
   */
  private handlePreviousAndNextData(): void {
    const i = this.items.cachedData.indexOf(this.current);
    this.previous = null;
    this.next = null;
    if (i > 0) {
      this.previous = this.items.cachedData[i - 1];
    }

    if (i < this.items.cachedData.length - 1) {
      this.next = this.items.cachedData[i + 1];
    }
  }

  /**
   * Navigates to the previous content
   */
  goPrevious(): void {
    this.trackerService.event('log', 'previous');
    if (this.previous) {
      this.body.emit(this.previous);
    }
  }

  /**
   * Navigates to the next content and loadMore if get the end of list
   */
  goNext(): void {
    this.trackerService.event('log', 'next');
    if (this.next && !this.items.loading) {
      const i = this.items.cachedData.indexOf(this.current) + 1;
      if (i === this.items.cachedData.length - 1) {
        this.items.loadMore();
        this.loadingSubscription = this.items.loading$.subscribe((loading) => {
          if (!loading && this.next) {
            this.body.emit(this.next);
            this.unsubscribeLoading();
          }
        });
      } else {
        this.body.emit(this.next);
      }
    }
  }

  /**
   *  Unsubscribe from previous observable.
   */
  private unsubscribeLoading() {
    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }
  }
}
