import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SuperSearchPageableResult } from 'app/modules/common/framework/pagination/pageable';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { LogDetailViewTO } from '../../log/model/log.model';
import { SearchResult } from '../models/search.model';

/**
 * Service to perform super search.
 */
@Injectable({
  providedIn: 'root',
})
export class SearchService {
  /**
   * The search result object initial state.
   */
  private static readonly INITIAL_RESULTS: any = Object.freeze({
    total: {
      value: 0,
      relation: 'eq',
    },
  });

  /**
   * Array of all indexed entity types.
   */
  private static readonly TYPES = ['crm_company', 'crm_contact', 'crm_log', 'crm_document', 'crm_account'];

  /**
   * Private subject to allow showing/hiding the search overlay.
   */
  private active = new BehaviorSubject<boolean>(false);

  /**
   * Observable to allow components to listen if the search is active.
   */
  active$ = this.active.asObservable().pipe(distinctUntilChanged());

  /**
   * The term to search for
   */
  searchTerm: string | null = null;

  /**
   * The current results object.
   */
  private results = { ...SearchService.INITIAL_RESULTS };

  /**
   * Subject to allow emitting changes to the result set.
   */
  private resultSubject = new BehaviorSubject<any>(this.results);

  /**
   * Observable for components to listen for result set changes.
   */
  results$ = this.resultSubject.asObservable();

  constructor(
    private http: HttpClient,
    private router: Router,
  ) {
    // Close the super search on navigation
    this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe(() => {
      this.active.next(false);
    });
  }

  /**
   * Performs the super search based on the provided search term for all types.
   *
   * @param term the search term, if falsy makes the search inactive
   */
  search(term: string | null): void {
    this.clearResults();
    this.searchTerm = term;

    this.active.next(!!term);

    if (term) {
      this.executeSearch(SearchService.TYPES);
    }
  }

  /**
   * Get more results for a specific type.
   *
   * @param type the type to search for
   * @param from the page start
   * @param limit the amount to limit the search
   */
  getMore(type: string): void {
    this.executeSearch([type], this.results[type].hits.length, 25);
  }

  /**
   * Clear the result set.
   */
  private clearResults(): void {
    this.results = { ...SearchService.INITIAL_RESULTS };
    this.resultSubject.next(this.results);
  }

  /**
   * Executes the search.
   *
   * @param type the type to search for or null for all
   * @param from the page start
   * @param limit the page size
   */
  private executeSearch(types: string[], from: number = 0, limit: number = 5): void {
    if (this.searchTerm) {
      this.http
        .get<SearchResult>(`${environment.apiUrl}/search/keyword`, {
          params: {
            type: types,
            from: from.toString(),
            size: limit.toString(),
            query: this.searchTerm,
          },
        })
        .pipe(
          tap((results) => {
            results.responses.forEach((item, index) => {
              if (item.hits) {
                const type = types[index];
                if (this.results[type]) {
                  this.results[type].hits = this.results[type].hits.concat(item.hits.hits);
                } else {
                  this.results[type] = item.hits;
                }

                this.results.total.value += item.hits.total.value;
              }
            });

            this.results.total.value = Object.keys(this.results).reduce((sum: number, type: string) => {
              const item = this.results[type];

              if (item.hits) {
                return sum + item.total.value;
              }

              return sum;
            }, 0);

            this.resultSubject.next(this.results);
          }),
        )
        .subscribe();
    }
  }

  /**
   * Executes the search for a log type to show on sidenav list.
   *
   * @param term the term to search for
   * @param from the page start
   * @param limit the page size
   */
  executeLogSearch(term: string, page: number = 0, limit: number = 25): Observable<SuperSearchPageableResult<LogDetailViewTO>> {
    const from: number = page * limit;

    return this.http.get<SuperSearchPageableResult<LogDetailViewTO>>(`${environment.apiUrl}/search/keyword`, {
      params: {
        type: 'crm_log',
        from: from.toString(),
        size: limit.toString(),
        query: term,
      },
    });
  }
}
