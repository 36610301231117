<ng-container [formGroup]="formGroup">
  <mat-form-field fxFlex="50">
    <mat-label>Consultant</mat-label>
    <mat-select formControlName="consultant">
      <mat-select-trigger>
        {{ formGroup.controls.consultant.value?.name }}
      </mat-select-trigger>

      <mat-option>
        <ngx-mat-select-search [formControl]="personSearchControl" placeholderLabel="Search" noEntriesFoundLabel="Nothing found">
          <mat-icon ngxMatSelectSearchClear fontIcon="mdi-close" class="small" />
        </ngx-mat-select-search>
      </mat-option>

      @for (personContact of consultantOptions$ | async; track personContact) {
        <mat-option [value]="personContact" class="option-2-line">
          <div class="option-line abs-text-overflow-ellipsis">
            {{ personContact.name }}
          </div>
          @if (personContact?.companyName) {
            <div class="option-line mat-caption abs-color-faded abs-text-overflow-ellipsis">
              {{ personContact.companyName }}
            </div>
          }
        </mat-option>
      }
      @if (formGroup.controls.consultant.value) {
        <mat-option [value]="formGroup.controls.consultant.value" class="option-2-line">
          <div class="option-line abs-text-overflow-ellipsis">
            {{ formGroup.controls.consultant.value.name }}
          </div>
          @if (formGroup.controls.consultant.value.companyName) {
            <div class="option-line mat-caption abs-color-faded abs-text-overflow-ellipsis">
              {{ formGroup.controls.consultant.value.companyName }}
            </div>
          }
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
  <mat-form-field fxFlex="50">
    <mat-label>Consultant Company</mat-label>
    <mat-select formControlName="externalRep" [compareWith]="externalRepCompare">
      <mat-option>
        <ngx-mat-select-search [formControl]="companySearchControl" placeholderLabel="Search" noEntriesFoundLabel="Nothing found">
          <mat-icon ngxMatSelectSearchClear fontIcon="mdi-close" class="small" />
        </ngx-mat-select-search>
      </mat-option>

      @for (companyContact of externalRepOptions$ | async; track companyContact) {
        <mat-option [value]="companyContact">
          {{ companyContact.name }}
        </mat-option>
      }

      @if (formGroup.controls.externalRep.value; as externalRep) {
        <mat-option [value]="externalRep">
          {{ formGroup.controls.externalRep.value.name }}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
</ng-container>
