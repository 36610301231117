import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TrackerService } from 'app/modules/common/business/tracker/services/tracker.service';
import { DateTime } from 'luxon';

/**
 * Service to handle with file
 */
@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(
    private trackerService: TrackerService,
    private titleService: Title,
  ) {}

  /**
   * Downloads the file through the given url
   * @param url the url of file
   */
  download(url: string) {
    if (!url) {
      return;
    }

    const targetWindow = parent ? parent.window : window;
    targetWindow.open(url);
  }

  /**
   * Opens a file downloaded from the server.
   *
   * @param file the file content as a blob
   */
  openFile(file: Blob, fileName: string): void {
    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(file);
    downloadLink.setAttribute('download', fileName);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    this.trackerService.event('file', 'download', { fileName });
  }

  /**
   * Generate a standard file name.
   *
   * @param name the file name identifier, the unique part of it
   * @param extension the file extension
   * @returns the file name to use
   */
  generateName(name: string, extension: string): string {
    const now = DateTime.now();

    return `ARMS_${name}_${now.toISODate()}_${now.toFormat('HH-mm')}.${extension}`;
  }

  /**
   * Open the browser print dialog for the current page.
   * Sets the page title to the name the file should have if printed to PDF.
   *
   * @param name the file name if printed to PDF
   * @returns a promise that resolves to void when the print dialog is closed
   */
  print(name: string): Promise<void> {
    document.body.classList.add('print-mode');

    return new Promise<void>((resolve, reject) => {
      setTimeout(() => {
        try {
          const currentTitle = this.titleService.getTitle();
          this.titleService.setTitle(name);
          window.print();
          this.titleService.setTitle(currentTitle);
          document.body.classList.remove('print-mode');
          resolve();
        } catch (err) {
          reject(err);
        }
      });
    });
  }
}
