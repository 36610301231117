@if (loading()) {
  @for (i of [].constructor(items()); track i) {
    <app-custom-list-item [ngStyle]="{ 'height.px': height }">
      <div fxFlex fxLayout="row" fxLayoutAlign="start center">
        @if (showAvatar) {
          <div class="abs-margin-right">
            <ngx-skeleton-loader
              appearance="circle"
              [theme]="{ 'height.px': 28, 'width.px': 28, 'border-radius': '50%', margin: 0 }"
            />
          </div>
        }

        <div fxFlex fxLayout="column">
          <ngx-skeleton-loader [count]="lines - 1" />
          <div style="width: 80%">
            <ngx-skeleton-loader />
          </div>
        </div>
      </div>
      <mat-divider />
    </app-custom-list-item>
  }
}
