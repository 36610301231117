import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { BasicContact } from '../../../contact/model/contact.model';
import { LogDetailViewTO, LogIcons, LogTypeEnum } from '../../model/log.model';

/**
 * The item of a log that is used in a list
 */
@Component({
  selector: 'app-log-list-item',
  templateUrl: './log-list-item.component.html',
  styleUrls: ['./log-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogListItemComponent {
  log = input.required<LogDetailViewTO>();

  private domParser = new DOMParser();

  /**
   * Log body without html tags.
   */
  strippedBody = computed(() => {
    return this.domParser.parseFromString(this.log().bodyHtmlContent, 'text/html').body.textContent;
  });

  logTypeEnum = LogTypeEnum;

  logIcons = new LogIcons();

  /**
   * Gets the names of contacts
   * @param contactsName the name of contacts involved with this log
   */
  getContactsName(contactsName: BasicContact[]): string | null {
    if (!contactsName || contactsName.length === 0) {
      return null;
    }
    let result = contactsName[0].name;
    if (contactsName.length > 1) {
      result += ` + ${contactsName.length - 1} Other(s)`;
    }
    return result;
  }
}
