import { Injectable } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';

/**
 * Service to simplify navigation related operations.
 */
@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(private route: ActivatedRoute) {}

  /**
   * Get the current data for the route in the main router outlet.
   *
   * @returns the data found
   */
  getCurrentMainRouteData(): Data {
    let route = this.route.root;

    while (route.firstChild) {
      route = route.firstChild;
    }

    return route?.snapshot.data;
  }
}
