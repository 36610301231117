@if (!isEmpty()) {
  <app-custom-list fxFlex fxLayout="column">
    <cdk-virtual-scroll-viewport fxFlex fxLayout="column" itemSize="44">
      <app-document-list-item
        *cdkVirtualFor="let document of documentData"
        [document]="document"
        [trackingFeature]="trackingFeature"
        [showLog]="showLog"
        [showEdit]="showEdit"
        [showDelete]="showDelete"
        (deleted)="onDelete($event)"
      />
    </cdk-virtual-scroll-viewport>
  </app-custom-list>
} @else {
  <app-no-data [entity]="'documents'" />
}
