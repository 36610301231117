import { Pipe, PipeTransform } from '@angular/core';

/**
 * When the value is empty it returns 'N/A'
 */
@Pipe({
  name: 'emptyValue',
})
export class EmptyValuePipe implements PipeTransform {
  transform(value?: string | null, defaultValue: string = 'N/A'): string {
    if (value) {
      return value;
    }

    return defaultValue;
  }
}
