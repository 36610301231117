import { Location } from '@angular/common';
import { Component, EventEmitter, input, Output } from '@angular/core';
import { SidenavService } from '../../services/sidenav.service';

/**
 * Generics component for sidenav used on router-outlet of app-sidenav
 */
@Component({
  selector: 'app-sidenav-outlet',
  templateUrl: './sidenav-outlet.component.html',
  styleUrls: ['./sidenav-outlet.component.scss'],
})
export class SidenavOutletComponent {
  /**
   * The sidenav title.
   */
  header = input<string | null>(null);

  /**
   * The sidenav subtitle.
   */
  subheader = input<string | null>(null);

  /**
   * Whether loading data
   */
  loading = input<boolean>(false);

  /**
   * Whether to show the back button.
   */
  showBackButton = input<boolean>(false);

  /**
   * Output that emits events when the back button is clicked.
   *
   * FIXME: We can't use the new angular output function as it doesn't provide an `observed` property.
   * Possibly it will be available in the future https://github.com/angular/angular/issues/54837
   */
  @Output()
  back = new EventEmitter<void>();

  constructor(
    private sidenavService: SidenavService,
    private location: Location,
  ) {}

  /**
   * Closes the sidenav
   */
  onCloseClick(): void {
    this.sidenavService.close();
  }

  /**
   * Navigate back
   */
  goBack(): void {
    // If there is a configured back function, just emit an event, otherwise fallback to location.back
    if (this.back.observed) {
      this.back.emit();
    } else {
      this.location.back();
    }
  }
}
