import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

/**
 * An event object, with the event identifier and a payload.
 */
interface AppEvent {
  type: string;
  payload: any;
}

/**
 * Service for a global event queue.
 */
@Injectable({
  providedIn: 'root',
})
export class EventQueueService {
  /**
   * Subject to emit the events.
   */
  private eventBrocker = new Subject<AppEvent>();

  /**
   * Gets and observable for the provided event.
   *
   * @param eventType the name of the event to listen to
   * @returns an observable that emits the payload of the provided event
   */
  on(eventType: string): Observable<any> {
    return this.eventBrocker.pipe(
      filter((event) => event.type === eventType),
      map((event) => event.payload)
    );
  }

  /**
   * Dispatchs the provided event with the provided payload.
   *
   * @param type the event to fire
   * @param payload the payload to emit
   */
  dispatch(type: string, payload: any = null): void {
    this.eventBrocker.next({ type, payload });
  }
}
