import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { AddConsultantDialogComponent } from '../components/add-consultant-dialog/add-consultant-dialog.component';
import { ConsultantUsageDTO } from '../model/consultant.model';

/**
 * Service for consultant related operations.
 */
@Injectable({
  providedIn: 'root',
})
export class ConsultantService {
  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
  ) {}

  /**
   * Get the number of accounts and opportunities for the given contact assigned to the given consultant company.
   *
   * @param idtContact the contact id
   * @param idtConsultantCompany the consultant company id
   * @returns an observable the emits the usage counts
   */
  getAccountsAndOpportunitiesCount(idtContact: number, idtConsultantCompany: number): Observable<ConsultantUsageDTO> {
    return this.http.get<ConsultantUsageDTO>(`${environment.apiUrl}/consultant/opportunities-accounts/count`, {
      params: {
        idtConsultantCompany,
        idtContact,
      },
    });
  }

  /**
   * Open dialog to associate a new consultant to a contact.
   *
   * @param idtContact the contact to be associated with the created consultant
   * @returns dialog reference
   */
  openNewConsultantDialog(idtContact: number): MatDialogRef<AddConsultantDialogComponent> {
    return this.dialog.open(AddConsultantDialogComponent, {
      width: '400px',
      data: {
        idtContact,
      },
    });
  }
}
