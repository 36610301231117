import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from 'app/modules/common/framework/dialog/dialog.service';
import { catchError, finalize, throwError } from 'rxjs';
import { TrackerService } from '../../../tracker/services/tracker.service';
import { Document } from '../../model/document.model';
import { DocumentService } from '../../services/document.service';

/**
 * An item of document for a list
 */
@Component({
  selector: 'app-document-list-item',
  templateUrl: './document-list-item.component.html',
  styleUrls: ['./document-list-item.component.scss'],
})
export class DocumentListItemComponent implements AfterViewInit {
  @Input()
  document!: Document;

  @Input()
  showLog = false;

  @Input()
  showEdit = false;

  @Input()
  showDelete = false;

  @Input()
  trackingFeature!: string;

  @Output()
  deleted = new EventEmitter<Document>();

  processing = signal(false);

  /**
   * Whether currently rendered in a sidenav.
   */
  sidenav = false;

  constructor(
    private documentService: DocumentService,
    private trackerService: TrackerService,
    private dialogService: DialogService,
    private router: Router,
    private route: ActivatedRoute,
    private el: ElementRef,
  ) {}

  ngAfterViewInit() {
    this.sidenav = !!this.el.nativeElement.closest('mat-sidenav');
  }

  /**
   * Downloads the document
   *
   * @param hash the hash of file stored in S3
   * @param filename the name of file
   */
  downloadDocument(): void {
    this.trackerService.event(this.trackingFeature, 'download_document', { idtDocument: this.document.idtDocument });
    this.documentService.download(this.document.idtDocument);
  }

  /**
   * Navigate to associated log details.
   *
   * @param e the click event triggered
   */
  navigateToLog(e: Event): void {
    e.stopPropagation(); // Avoid downloading the file
    this.trackerService.event(this.trackingFeature, 'open_log', { idtDocument: this.document.idtDocument });

    this.router.navigate(['', { outlets: { sidenav: ['log', this.document.idtLog] } }]);
  }

  /**
   * Navigate to edit document page.
   *
   * @param e the click event triggered
   */
  navigateToEdit(e: Event): void {
    e.stopPropagation(); // Avoid downloading the file
    this.trackerService.event(this.trackingFeature, 'edit_document', { idtDocument: this.document.idtDocument });

    // If rendered in the sidenav, navigate relative to the current route, otherwise navigate to the document edit base route
    if (this.sidenav) {
      this.router.navigate(['doc', this.document.idtDocument], { relativeTo: this.route });
    } else {
      this.router.navigate(['', { outlets: { sidenav: ['doc', this.document.idtDocument] } }]);
    }
  }

  /**
   * Delete the document.
   *
   * @param e the click event triggered
   */
  deleteDocument(e: Event): void {
    e.stopPropagation(); // Avoid downloading the file
    this.trackerService.event(this.trackingFeature, 'delete_document', { idtDocument: this.document.idtDocument });

    this.dialogService
      .showConfirm('Are you sure you want to remove this document from the system?')
      .afterClosed()
      .subscribe((confirm) => {
        if (confirm) {
          this.processing.set(true);
          this.documentService
            .delete(this.document.idtDocument)
            .pipe(
              finalize(() => {
                this.processing.set(false);
              }),
              catchError((err) => {
                this.dialogService.showError(err.error.message || 'Error deleting document');
                return throwError(() => err);
              }),
            )
            .subscribe(() => {
              this.dialogService.showSuccess('Document deleted successfully');
              this.deleted.emit(this.document);
            });
        }
      });
  }
}
