import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, ElementRef, input, OnInit, signal, viewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { combineLatest, fromEvent } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { User } from '../user.model';
import { UserService } from '../user.service';
/**
 * Component for a user autocomplete input field.
 */
@Component({
  selector: 'app-user-autocomplete',
  templateUrl: './user-autocomplete.component.html',
  styleUrls: ['./user-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAutocompleteComponent implements OnInit {
  /**
   * The form control instance.
   */
  control = input.required<FormControl<User[]>>();

  /**
   * The field label.
   */
  label = input<string>('User');

  /**
   * Optional placeholder to show in the field.
   */
  placeholder = input<string>('Add user...');

  /**
   * Variable to hold all users fetched from the server.
   */
  private allUsers: User[] = [];

  /**
   * Whether to show the (author) for the first user.
   */
  textToFirst = input(false);

  /**
   * The search input element reference.
   */
  searchInput = viewChild.required<ElementRef>('input');

  /**
   * The search results.
   */
  filteredResults = signal<User[]>([]);

  constructor(
    private userService: UserService,
    private cdr: ChangeDetectorRef,
    private destroyRef: DestroyRef,
  ) {}

  ngOnInit(): void {
    this.configureAutocomplete();

    // Handle any changes performed to the form control outside this component so that we trigger change detection here too
    combineLatest([this.control().valueChanges, this.control().statusChanges])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.cdr.markForCheck();
      });

    this.userService
      .searchEmployeesByTerm('')
      .pipe(
        tap((allUsers) => {
          this.allUsers = allUsers;
          this.filteredResults.set(allUsers);
        }),
      )
      .subscribe();
  }

  /**
   * Configure the input element autocomplete behavior.
   */
  private configureAutocomplete() {
    fromEvent(this.searchInput().nativeElement, 'input')
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        debounceTime(500),
        tap(() => this.searchEntities(this.searchInput().nativeElement.value.toLowerCase())),
      )
      .subscribe();
  }

  /**
   * Searches for users.
   *
   * @param term the search term
   */
  searchEntities(term: string) {
    if (term && term.length > 0) {
      this.filteredResults.set(
        this.allUsers.filter(
          (u) => u.initials.toLocaleLowerCase().includes(term.toLocaleLowerCase()) || u.name.toLocaleLowerCase().includes(term.toLocaleLowerCase()),
        ),
      );
    } else {
      this.filteredResults.set(this.allUsers);
    }
  }

  /**
   * Remove an entity for the selected list.
   *
   * @param idtUser the user id to be removed
   */
  remove(idtUser: number): void {
    this.control().setValue(this.control().value.filter((e) => e.idtUser !== idtUser));
  }

  /**
   * Verifies if the provided user is already selected.
   *
   * @param idtUser the user id to verify
   * @returns true, if the user is already selected
   */
  isIncluded(idtUser: number): boolean {
    return this.control().value.some((c) => c.idtUser === idtUser);
  }

  /**
   * Adds an etity to the selected list when an option is selected.
   *
   * @param event the event that triggered the selection
   */
  selected(event: MatAutocompleteSelectedEvent): void {
    if (event.option.value) {
      this.selectUser(event.option.value);
    }
  }

  /**
   * Adds a user to the selected list.
   *
   * @param user the user entity
   */
  private selectUser(user: User): void {
    if (!this.isIncluded(user.idtUser)) {
      this.control().setValue([...this.control().value, user]);
    }

    this.searchInput().nativeElement.value = '';
    this.searchInput().nativeElement.dispatchEvent(new Event('input'));
  }
}
