import { Component, computed, input, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * Component created to isolate the InnerHTMl email style classes.
 */
@Component({
  selector: 'app-log-content',
  templateUrl: './log-content.component.html',
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class LogContentComponent {
  /**
   * The full log data.
   */
  bodyHtmlContent = input.required<string>();

  /**
   * The log contents.
   */
  content = computed(() => {
    return this.sanitizer.bypassSecurityTrustHtml(this.bodyHtmlContent());
  });

  constructor(private sanitizer: DomSanitizer) {}
}
