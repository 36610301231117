import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
import { Draft } from '../model/draft.model';

/**
 * Manages user drafts.
 */
@Injectable({
  providedIn: 'root',
})
export class DraftService {
  constructor(private http: HttpClient) {}

  /**
   * Build a new draft instance for the given type.
   * @param type
   * @returns
   */
  build<T>(type: string): Draft<T> {
    return {
      id: null,
      idtUser: null,
      type,
    };
  }

  /**
   * Save draft data.
   * @param draft
   * @returns
   */
  save<T>(draft: Draft<T>): Observable<Draft<T>> {
    return this.http.post<Draft<T>>(`${environment.apiUrl}/draft`, draft);
  }

  /**
   * Discard draft data. Delays for 2 seconds to give time for the server commits the operation.
   * @param id
   * @returns
   */
  discard(id: string): Observable<void> {
    return this.http.delete<void>(`${environment.apiUrl}/draft/${id}`).pipe(delay(2000));
  }

  /**
   * Get all current user drafts.
   * @returns
   */
  getUserDrafts<T>(type: string): Observable<Draft<T>[]> {
    return this.http.get<Draft<T>[]>(`${environment.apiUrl}/draft/user/${type}`);
  }

  /**
   * Get the number of existing user drafts for the provided type.
   *
   * @param type the type of the draft (example: "log")
   * @returns the number of drafts for the type
   */
  getUserDraftsCount(type: string): Observable<number> {
    return this.http.get<number>(`${environment.apiUrl}/draft/user/${type}/count`);
  }

  /**
   * Get user draft by id.
   * @param id
   * @returns
   */
  getDraft<T>(id: string): Observable<Draft<T>> {
    return this.http.get<Draft<T>>(`${environment.apiUrl}/draft/${id}`);
  }
}
