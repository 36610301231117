@if (disabled()) {
  <span class="abs-text-overflow-ellipsis">{{ displayText }}</span>
} @else {
  <div class="abs-link display-text tw-flex tw-flex-row tw-items-center" (contextmenu)="openContextMenu($event)">
    <span class="abs-text-overflow-ellipsis" cdkOverlayOrigin #trigger="cdkOverlayOrigin" (click)="clicked($event)">
      {{ displayText }}
    </span>
    <mat-icon fontIcon="mdi-chevron-down" class="context-menu-arrow tw-flex-none" (click)="openContextMenu($event)" />
  </div>

  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="isOpen()"
    [cdkConnectedOverlayHasBackdrop]="true"
    cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
    (backdropClick)="close()"
  >
    <div class="mat-elevation-z8 wrapper tw-flex tw-flex-col" ngClass.lt-md="fullscreen" ngClass.gt-sm="abs-margin-double-horizontal">
      <mat-toolbar color="primary">
        <ng-content />
        <button mat-icon-button (click)="close()" fxShow fxHide.gt-sm><mat-icon fontIcon="mdi-close" /></button>
      </mat-toolbar>
      <div class="abs-padding tw-flex tw-flex-row tw-flex-wrap">
        @for (option of options; track option) {
          <div class="tw-w-1/2">
            @if (option.type === 'link') {
              <a
                class="abs-padding abs-link tw-flex tw-flex-row tw-items-center"
                (click)="trackClick(option)"
                [routerLink]="option.routerLink"
                [queryParams]="option.queryParams"
              >
                <mat-icon [fontIcon]="option.icon" class="abs-margin-right" color="primary" />
                <span class="tw-flex-1">{{ option.text }}</span>
              </a>
            }
            @if (option.type === 'button' && option.click) {
              <a class="abs-padding abs-link tw-flex tw-flex-row tw-items-center" (click)="trackClick(option); option.click(); isOpen.set(false)">
                <mat-icon [fontIcon]="option.icon" class="abs-margin-right" color="primary" />
                <span class="tw-flex-1">{{ option.text }}</span>
              </a>
            }
          </div>
        }
      </div>
    </div>
  </ng-template>
}
