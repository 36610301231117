import { ChangeDetectionStrategy, Component, EventEmitter, Output, computed, input } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { BasicContact } from '../../../contact/model/contact.model';
import { Draft } from '../../../draft/model/draft.model';
import { DraftService } from '../../../draft/services/draft.service';
import { LogDraft, LogIcons, LogTypeEnum } from '../../model/log.model';

/**
 * Log draft item representation.
 */
@Component({
  selector: 'app-log-list-draft-item',
  templateUrl: './log-list-draft-item.component.html',
  styleUrls: ['./log-list-draft-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogListDraftItemComponent {
  private domParser = new DOMParser();

  /**
   * Draft instance.
   */
  draft = input.required<Draft<LogDraft>>();

  /**
   * Log body without html tags.
   */
  strippedBody = computed(() => {
    if (this.draft().data?.bodyHtmlContent) {
      return this.domParser.parseFromString(this.draft().data!.bodyHtmlContent!, 'text/html').body.textContent;
    }

    return '';
  });

  /**
   * Log type enum.
   */
  logTypeEnum = LogTypeEnum;

  logIcons = new LogIcons();

  /**
   * Whether the log is being discarded.
   */
  discarding = false;

  constructor(private draftService: DraftService) {}

  /**
   * Event emitted after a draft is discarded.
   */
  @Output()
  discard = new EventEmitter<string>();

  /**
   * Gets the names of contacts
   * @param contactsName the name of contacts involved with this log
   */
  getContactsName(contactsName: BasicContact[]): string | null {
    if (!contactsName || contactsName.length === 0) {
      return null;
    }
    let result = contactsName[0].name;
    if (contactsName.length > 1) {
      result += ` + ${contactsName.length - 1} Other(s)`;
    }
    return result;
  }

  /**
   * Get the icon for the log type.
   * @param value  value for the log type.
   * @returns
   */
  getIcon(value?: LogTypeEnum | null): string {
    return value ? this.logIcons.getIconValue(value) : '';
  }

  /**
   * Discard the draft.
   */
  discardDraft(event: Event): void {
    event.stopPropagation(); // Avoid the item line click action.

    this.discarding = true;

    const id = this.draft().id;
    if (id) {
      this.draftService
        .discard(id)
        .pipe(
          finalize(() => {
            this.discarding = false;
          }),
        )
        .subscribe(() => {
          this.discard.next(id);
        });
    }
  }
}
