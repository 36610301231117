import { LogTypeEnum } from '../../log/model/log.model';

/**
 * Opportunity view model.
 */
export interface OpportunityViewTO {
  idtOpportunity: number;
  idtContact: number;
  contactName: string;
  idtKeyContact: number;
  keyContactName: string;
  consultantName: string;
  consultantCompanyName: string;
  status: OpportunityStatusEnum;
  probabilityTiming: OpportunityPropabilityTimingEnum;
  size: number;
  portfolio: string;
  displayName: string;
  idtConsultant?: number;
  idtConsultantCompany?: number;
  idtLastLog?: number;
  lastLogDate?: string;
  creationDate: string;
  idtPortfolio: number;
  hasTransaction: boolean;
}

/**
 * Opportunity full model.
 */
export interface OpportunityTO {
  idtOpportunity: number;
  idtContact: number;
  idtKeyContact: number;
  idtPortfolio: number;
  idtConsultant: number;
  idtConsultantCompany: number;
  status: OpportunityStatusEnum;
  closedStatus: OpportunityClosedStatusEnum;
  statusComments: string;
  lostDate: string;
  lostReason: string;
  lostWho: string;
  knownCompetition: string;
  probabilityTiming: OpportunityPropabilityTimingEnum;
  size: number;
  finalPitch: string;
  researchApproval: boolean;
  rfp: boolean;
  fundingDate: string;
  customPortfolio: boolean;
  rfpDueDate?: string;
  blackout: boolean;
}

/**
 * View for oportunity details.
 */
export interface OpportunityDetailsViewTO extends OpportunityTO {
  contactName: string;
  keyContactName: string;
  consultantName: string;
  consultantCompanyName: string;
  displayName: string;
  portfolio: string;
  rfpStatus: string;
  rfpDueDate: string;
  lead: string;
  leadBackup: string;
  consultantLead: string;
  consultantLeadBackup: string;
  creationDate: string;
  lastLogDate?: string;
  idtLastLog?: number;
  hasTransaction: boolean;
}

/**
 * Data required to create or update an opportunity.
 */
export interface OpportunityInputDTO {
  idtLog?: number;
  idtOpportunity?: number;
  idtContact: number;
  idtKeyContact: number;
  idtPortfolio: number;
  idtConsultant: number;
  idtConsultantCompany: number;
  status: OpportunityStatusEnum;
  closedStatus: OpportunityClosedStatusEnum;
  statusComments: string;
  lostDate: Date;
  lostReason: string;
  lostWho: string;
  knownCompetition: string;
  probabilityTiming: OpportunityPropabilityTimingEnum;
  size: number;
  finalPitch: Date;
  rfpStatus: RfpStatusEnum;
  rfpDueDate: string;
}

/**
 * Timing probabilities for opportunities.
 */
export enum OpportunityPropabilityTimingEnum {
  LONG = 'LONG',
  MEDIUM = 'MEDIUM',
  SHORT = 'SHORT',
}

/**
 * Opportunity status options.
 */
export enum OpportunityStatusEnum {
  COLD = 'COLD',
  WARM = 'WARM',
  HOT = 'HOT',
  BOILING = 'BOILING',
}

/**
 * Opportunity closed status options.
 */
export enum OpportunityClosedStatusEnum {
  WON = 'WON',
  LOST = 'LOST',
}

/**
 * Enum values for RFP file status.
 */
export enum RfpStatusEnum {
  POTENTIAL = 'POTENTIAL',
  IN_PROGRESS = 'IN_PROGRESS',
  SUBMITTED = 'SUBMITTED',
}

/**
 * Model class for RFP file entity.
 */
export interface RfpFileTO {
  idtRfpFile: number;
  idtOpportunity: number;
  status: RfpStatusEnum;
  name: string;
  creationDate: string;
}

export interface OpportunitiesByConsultantTO {
  idtContact: number;
  name: string;
  size: number;
  portfolios: string[];
  count: number;
  idtOpportunities: number[];
}

/**
 * A single item in the evolution timeline of an opportunity.
 */
export interface OpportunityEvolutionViewTO {
  [key: string]: any;
  userInitials: string;
  referenceDate: Date;
  idtLog?: number;
  logType?: LogTypeEnum;
  logSubject?: string;
  logBodyContent?: string;
  status?: OpportunityStatusEnum;
  closedStatus?: OpportunityClosedStatusEnum;
  probabilityTiming?: OpportunityPropabilityTimingEnum;
  portfolio?: string;
  previousStatus?: OpportunityStatusEnum;
  previousClosedStatus?: OpportunityClosedStatusEnum;
  previousProbabilityTiming?: OpportunityPropabilityTimingEnum;
  previousPortfolio?: string;
  accountName?: string;
  changedTransaction: boolean;
}

export interface UpcomingSubscriptionsAndRedemptionsViewTO {
  idtTransaction: number;
  value: string;
  portfolio: string;
  transactionMode: string;
  transactionDate: string;
  client: string;
  pendingStatus: string;
  consultantCompany: string;
}

export interface OpportunityReportDTO {
  status: string;
  portfolio: string;
  contactName: string;
  lead: string;
  leadBackup: string;
  size: string;
  consultantCompanyName: string;
  lastLogDate: string;
  consultant: string;
}

export interface RFPReportDTO {
  rfpDueDate: string;
  rfpStatus: string;
  portfolio: string;
  contactName: string;
  size: string;
  consultant: string;
  consultantCompanyName: string;
}

export interface UpcomingPresentationsViewTO {
  idtTask: number;
  presentationDate: string;
  portfolio: string;
  dueDate: string;
  attendees: string;
  contact: string;
  consultant: string;
  value: string;
  meetingType: string;
}

export interface StandardFeeData {
  portfolio: string;
  feeSchedule: string;
  managementAndPerformanceFee: string;
  liquidity: string;
}

export interface OpportunityReportData {
  executionDate: string;
  user: string;
  subscriptions: UpcomingSubscriptionsAndRedemptionsViewTO[];
  totalSubscriptions: string;
  redemptions: UpcomingSubscriptionsAndRedemptionsViewTO[];
  totalRedemptions: string;
  opportunities: OpportunityReportDTO[];
  totalOpportunities: string;
  rfps: RFPReportDTO[];
  totalRfps: string;
  lowerTransactionLimit: string;
  upcomingPresentations: UpcomingPresentationsViewTO[];
  standardFeeData: StandardFeeData[];
}
