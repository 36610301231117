import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

/**
 * Sidenav Service
 */
@Injectable({
  providedIn: 'root',
})
export class SidenavService {
  constructor(private router: Router) {}

  /**
   * Navigates to sidenav
   * @param side
   */
  navigate(side: unknown[], extras?: NavigationExtras): Promise<boolean> {
    return this.router.navigate(['', { outlets: { sidenav: side } }], extras);
  }

  /**
   * Removes sidenav from url to close it
   */
  close(): Promise<boolean> {
    return this.router.navigate([{ outlets: { sidenav: null } }]);
  }
}
