<app-custom-list fxFlex fxLayout="column">
  <div class="draft-list abs-scroll-auto">
    @for (draft of draftData | async; track draft) {
      <app-log-list-draft-item class="abs-opacity" [draft]="draft" (discard)="onDraftDiscarded($event)" [routerLink]="['.', 'draft', draft.id]" />
    }
  </div>

  @if (logData && !logData.hasData) {
    <app-no-data [entity]="'Logs'" />
  }

  <cdk-virtual-scroll-viewport
    fxFlex
    fxLayout="column"
    itemSize="80"
    [ngClass]="{ 'abs-scroll-hidden': !logData || (logData.loading && logData.totalElements === 0) }"
  >
    <!-- Skeleton items while loading -->
    <app-list-skeleton-loader [lines]="4" [height]="80" [items]="20" [loading]="!logData || (logData.loading && logData.totalElements === 0)" />

    <app-log-list-item *cdkVirtualFor="let log of logData; let index = index" [log]="log" (click)="onClick(log, index)" />
  </cdk-virtual-scroll-viewport>
</app-custom-list>
